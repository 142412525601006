import React, { useEffect, useRef, useState } from "react"
import "../../commencss/bootstrap.min.css"
import "../../commencss/custom.css"
import "../../commencss/responsive.css"
import PageLayout from "../../Components/PageLayout"
import { Link } from "react-router-dom"
import MailchimpSubscribe from "react-mailchimp-subscribe"

const MailchimpForm = (props) => {
  const url = `https://shop.us17.list-manage.com/subscribe/post?u=26d83d323625f77ff3625c660&amp;id=42159f3849`
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  )
}

const CustomForm = ({ status, message, onValidated }) => {
  const [showsuccess, setShowsuccess] = useState(false)
  const [error, setShowerror] = useState(false)
  const [user, setUser] = useState({
    EMAIL: "",
    FNAME: "",
    MMERGE5: "",
    MMERGE6: "",
    MMERGE7: "",
  })
  const handleInputs = (e) => {
    let name = e.target.name
    let value = e.target.value
    setUser({ ...user, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onValidated({
      EMAIL: user.EMAIL,
      FNAME: user.FNAME,
      MMERGE5: user.MMERGE5,
      MMERGE6: user.MMERGE6,
      MMERGE7: user.MMERGE7,
    })
    if (
      user.EMAIL === "" ||
      user.FNAME === "" 
    ) {
      setShowerror(true)
    } else {
      setShowerror(false)
      setUser({
        EMAIL: "",
        FNAME: "",
        MMERGE5: "",
        MMERGE6: "",
        MMERGE7: "",
      })

      setShowsuccess(true)
      setTimeout(() => {
        setShowsuccess(false)
      }, 15000)
    }
  }
  return (
    <form onSubmit={(e) => handleSubmit(e)} noValidate>
      {error ? (
        <text style={{ color: "red", position: "absolute", right:30, top: 0 }}>
         Please enter required field values.
        </text>
      ) : null}
      <div id="mc_embed_signup_scroll">
        <div class="mc-field-group">
          <input
            type="text"
            name="FNAME"
            onChange={handleInputs}
            class="required"
            value={user.FNAME}
            id="mce-FNAME"
            required
          />
          <label for="mce-FNAME">
            Name <span class="asterisk">*</span>
          </label>
        </div>

        <div class="mc-field-group">
          <input
            type="text"
            name="EMAIL"
            onChange={handleInputs}
            class="required email"
            value={user.EMAIL}
            id="mce-EMAIL"
            required
          />
          <label for="mce-EMAIL">
            Email Address <span class="asterisk">*</span>
          </label>
        </div>
        <div class="mc-field-group fullwidth">
          <p>
            Share your social handles and we’ll give you a personal shoutout.
          </p>
        </div>
        <div class="mc-field-group">
          <input
            type="text"
            className="required"
            onChange={handleInputs}
            value={user.MMERGE5}
            name="MMERGE5"
            id="mce-MMERGE5"
            />
          <label for="mce-MMERGE5" className="graycolor">Twitter </label>
        </div>
        <div class="mc-field-group marginset">
          <input
            type="text"
            className="required"
            name="MMERGE6"
            value={user.MMERGE6}
            onChange={handleInputs}
            id="mce-MMERGE6"
            />
          <label for="mce-MMERGE6" className="graycolor">Discord </label>
        </div>

        <div class="mc-field-group fullwidth">
          <input
            type="text"
            className="required"
            name="MMERGE7"
            value={user.MMERGE7}
            onChange={handleInputs}
            id="mce-MMERGE7"
            />
          <label for="mce-MMERGE7">Anything Else? </label>
        </div>

          {/* <div class="floating">
            <input
              type="text"
              className="required"
              name="MMERGE7"
              id="mce-MMERGE7"
              value={user.MMERGE7}
              onChange={handleInputs}
              required
            />
            <label
              for="mce-MMERGE7"
              class="floating__label"
              data-content="Anything else?"
            >
              <span class="hidden--visually">Anything else?</span>
            </label>
          </div> */}

        <div style={{ display: "none" }} aria-hidden="true">
          <input
            type="text"
            name="b_26d83d323625f77ff3625c660_42159f3849"
            tabindex="-1"
          />
        </div>
        <div class="clear mat20">
          <input
            type="submit"
            value="Sign Up"
            name="Sign Up"
            id="mc-embedded-Sign Up"
            class="button btn"
          />
        </div>
        <div style={{ clear: "both" }}></div>
        {showsuccess && (
          <p className="massigessucc">
            We are <strong>thrilled to have you on board.</strong> Check your email (and “Junk”
            of course).
          </p>
        )}
      </div>
    </form>
  )
}

export default function Shoppers() {
  const videoRef = useRef()
  useEffect(() => {
    videoRef.current.play()
  }, [])
  const [activevideo, setActivevideo] = useState(0)
  const handleTimeUpdate = (event) => {
    if (event.target.currentTime > 4 && event.target.currentTime < 21) {
      setActivevideo(1);
    } else if (event.target.currentTime > 21 && event.target.currentTime < 29 ) {
      setActivevideo(2);
    } else {
      setActivevideo(0)
    }
  }

  useEffect(() => {
    var hashValue = window.location.hash.substr(1)
    if (hashValue == "shopper_signup") {
      setTimeout(() => {
        document.getElementById("mc_embed_signup").scrollIntoView({top:"-150px"});
      }, 0);
    } else if (hashValue=="shopper_experience"){
      setTimeout(() => {
        document.getElementById("shopper_animation").scrollIntoView({top:"-50px"});
      }, 0);
    }
  }, [])

  return (
    <PageLayout>
      <section className="main-banner innerbannermain">
        <div className="d-flex bannerboxmain">
          <div className="bannerdetails">
            {/* <span className="pagetitle">Shoppers</span> */}
            <h1 className="title1">
              Simplest way&nbsp;
              <span>to</span> save&nbsp;
              <span>more</span> <span>@ your</span>
              &nbsp;favorite stores
            </h1>
            <div className="marketscomprised">
            <p>
              We help sellers to slash their costs and <label> give you better
              discounts!</label>
            </p>
            <a href="#mc_embed_signup" className="btn">
              Buy on <span>dema</span>
              </a>
            </div>

            <span className="point1">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon1.png"
              />
            </span>
            <span className="point2">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon2.png"
              />
            </span>
            <span className="point3">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon3.png"
              />
            </span>
            <span className="point4">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon4.png"
              />
            </span>
            <span className="point5">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon5.png"
              />
            </span>
          </div>

          <div className="banner-pic">
            <figure>
              <img
                alt=""
                className="img-fluid"
                src="/images/shoppers/banner-img-2.png"
              />
            </figure>
          </div>
        </div>
      </section>

      <section className="magic-work" id="shopper_animation">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 leftpart">
              <h2 className="title1 d-block d-md-none">
                <span>How does the</span> magic work?
              </h2>
              <div className="sellerpicvideo">
                <video
                  ref={videoRef}
                  playsInline
                  id="video"
                  onTimeUpdate={handleTimeUpdate}
                  controls={false}
                  autoPlay={true}
                  loop={true}
                  muted
                >
                  <source
                    src="https://demawebsite.s3.us-east-2.amazonaws.com/Shopper-Animation.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-md-6 magicworkright">
              <h2 className="title1 mobilehidetitlemagic">
                <span>How does the</span> magic work?
              </h2>

           <div className="scrollbar position-relative" id="style-4">
                <div className="force-overflow">
                  <div
                    className={activevideo == 0 ? "item itemactive" : "item"}
                  >
                    <h6 className="fw-bold primary-color">
                      Install a browser extension
                    </h6>
                    <p>Sign-up and add your address, payment details etc.</p>
                  </div>
                  <div
                    className={activevideo == 1 ? "item itemactive" : "item"}
                  >
                    <h6 className="fw-bold primary-color">Shop on Amazon</h6>
                    <p>
                      A pop-up alerts you when you can save and add items to
                      your cart.
                    </p>
                  </div>
                  <div
                    className={activevideo == 2 ? "item itemactive" : "item"}
                  >
                    <h6 className="fw-bold primary-color">
                      Check out on&nbsp;<label className="demafont">dema</label>
                      &nbsp;and save!
                    </h6>
                    <p>
                      Orders ship from the same seller with the same return
                      policies.
                    </p>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </section>

      <section className="friendly-market buyer pt">
        <div className="container">
          
            <div className="col-md-12 text-center">
              <div className="section-header d-inline-block text-end">
                <h1 className="title1">
                  Everyday <span>is </span> Black Friday
                </h1>
                <p>
                  powered by&nbsp;
                  <label className="demafont">
                    d<small>e</small>ma
                  </label>
                </p>
              </div>
            </div>
          

          <div className="row direction align-items-center">
            <div className="col-md-6 sellerpic rightpic">
              <img
                src="images/shoppers/asset-1.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <div className="friendly-market-content p-0">
                <h3 className="primary-color">
                  Start with 4% savings everytime
                </h3>
                <p>
                  Shop anywhere. When you add an item to your&nbsp;
                  <label className="demafont">dema</label> cart and checkout,
                  you’ll get an automatic 4% discount.
                </p>
                <Link className="readmore" to="/faq#Shoppers/Savings">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6">
              <div className="friendly-market-content">
                <h3 className="primary-color">Own your marketplace</h3>
                <p>
                  Every sale on&nbsp;
                  <label className="demafont">dema</label> earns you ownership
                  tokens which you can use to increase discounts, get early
                  access to deals, vote on proposals, etc...
                </p>
                <Link className="readmore"  to="/faq#Shoppers/Power_Features">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 sellerpic">
              <img src="images/seller/img-8.svg" alt="" className="img-fluid" />
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6 sellerpic rightpic">
              <img
                src="images/shoppers/asset-2.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <div className="friendly-market-content p-0">
                <h3 className="primary-color">Buy more - save more!</h3>
                <p>
                  Buy 3, 4 or more of the same item & save more as you add more.
                  How? We pass the shipping overhead savings to you!
                </p>
                <Link className="readmore" to="/faq#Shoppers/Savings">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6">
              <div className="friendly-market-content">
                <h3 className="primary-color">Pay with cash - save more!</h3>
                <p>
                  Your bank account & crypto assets can work harder for you!
                  Save another 2% when you pay with them. Did you annualize that
                  ROI...? Huge, right?!
                </p>
                <Link className="readmore"  to="/faq#Shoppers/Savings">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 sellerpic">
              <img
                src="images/shoppers/asset-4.svg"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6 sellerpic rightpic">
              <img
                src="images/shoppers/asset-5.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <div className="friendly-market-content p-0">
                <h3 className="primary-color">
                  Your reputation saves you more!
                </h3>
                <p>
                  Your excellent low return purchase history earns you more
                  ownership tokens & even greater discounts! See... good
                  judgment does earn you more!
                </p>
                <Link className="readmore"  to="/faq#Shoppers/Power_Features">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6">
              <div className="friendly-market-content">
                <h3 className="primary-color">Save with power team buys</h3>
                <p>
                  Buy as a group of 3 or more, ship to the same address & save
                  10%. The larger your group’s purchase, the greater the
                  savings!
                </p>
                <Link className="readmore"  to="/faq#Shoppers/Savings">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 sellerpic">
              <img
                src="images/shoppers/asset-3.svg"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6 sellerpic rightpic">
              <img
                src="images/shoppers/asset-7.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <div className="friendly-market-content p-0">
                <h3 className="primary-color">Buy mint returns & save more!</h3>
                <p>
                  Buy open-box mint condition returns & save up to 30%. You also
                  reduce landfill buildup. That’s humanity smart. That’s&nbsp;
                  <label className="demafont">dema</label> smart!
                </p>
                <Link className="readmore"  to="/faq#Shoppers/Savings">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="row direction shopperspagbottem align-items-center">
            <div className="col-md-6">
              <div className="friendly-market-content">
                <h3 className="primary-color">Refer & earn more!</h3>
                <p>
                  As&nbsp;<label className="demafont">dema</label>&nbsp;grows, we all
                  win. So bring your family, friends or your favorite sellers &
                  earn more tokens and even greater discounts!
                </p>
                <Link className="readmore" to="/faq#Shoppers/Power_Features">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 sellerpic">
              <img
                src="images/shoppers/asset-6.svg"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>

          {/* <div className="direction shopperspageprocess">
             <div className="sellerbox-left">
              <img
                src="images/shoppers/asset-1.svg"
                alt=""
                className="img-fluid"
              />
              <div className="friendly-market-content mt-3 mt-md-0 mb-3 mb-md-0">
                <h3 className="fw-bold primary-color">
                  Save 4% on every order
                </h3>
                <p>
                  Shop anywhere. When you add an item to your&nbsp;
                  <label className="demafont">dema</label>&nbsp; cart and
                  checkout, you’ll get an automatic 4%.
                </p>
                <Link className="readmore" to="#">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>

          </div> */}
        </div>
      </section>

      {/* <section className="seller-experience pt pb">
        <div className="container">
          <div className="section-header">
            <h1 className="title1">
              <span>Buyer</span> experiences
            </h1>
            <p>Hear what our customers are saying!</p>
          </div>
          <div className="row">
            <div className="experience-block">
              <div className="d-flex align-items-center mb-4">
                <img
                  src="images/gianaicon.svg"
                  alt=""
                  width="40"
                  className="img-fluid me-1 me-lg-3"
                />
                <h5 className="fw-bold m-0 primary-color">Giana Kenter</h5>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen.
              </p>
              <ul>
                <li>
                  <img
                    src="images/seller/audioicon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </li>

                <li>
                  <img
                    src="images/seller/videoicon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </li>
              </ul>
            </div>
            <div className="experience-block">
              <div className="d-flex align-items-center mb-4">
                <img
                  src="images/gianaicon.svg"
                  alt=""
                  width="40"
                  className="img-fluid me-1 me-lg-3"
                />
                <h5 className="fw-bold m-0 primary-color">Hanna Septimus</h5>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen.
              </p>
              <ul>
                <li>
                  <img
                    src="images/seller/audioicon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </li>

                <li>
                  <img
                    src="images/seller/videoicon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </li>
              </ul>
            </div>
            <div className="experience-block">
              <div className="d-flex align-items-center mb-4">
                <img
                  src="images/gianaicon.svg"
                  alt=""
                  width="40"
                  className="img-fluid me-1 me-lg-3"
                />
                <h5 className="fw-bold m-0 primary-color">Maren Philips</h5>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen.
              </p>
              <ul>
                <li>
                  <img
                    src="images/seller/audioicon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </li>

                <li>
                  <img
                    src="images/seller/videoicon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}

      <section className="boost position-relative" id="mc_embed_signup">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <h1 className="title1">
                <span>Shop effortlessly!</span> Save more!
              </h1>
            </div>
            <div className="col-md-8 shoppersform">
              <MailchimpForm />
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  )
}
