import React from "react";
import PageLayout from "../../Components/PageLayout";
import { Link } from "react-router-dom";
import Bannermain from "../../Components/Banner";
import Makingyourdecisioneasier from "../../Components/makingyourdecision-table";
import Connectstore from "../../Components/Connectstore";

export default function Home() {
   return (
     <PageLayout>
       <Bannermain />
       <section className="ourfeatures">
         <div className="container">
           <h1 className="title1">
             <span>Your Power</span> Features
           </h1>
           <div className="ourfeaturesbox threestap">
             <div className="icontitle d-flex">
               <figure>
                 <img
                   alt=""
                   className="img-fluid"
                   src="/images/our-features/features3.svg"
                 />
               </figure>
               <strong>
                 <span>A buy-box that</span> beats Amazon’s
               </strong>
             </div>
             <p>
               <label className="demafont">dema</label> picks your products even
               when Amazon’s buy-box doesn’t. You win more & increase your
               sales!
             </p>
             <Link to="/faq#About_Dema/Power_Features">
               learn more
               <img
                 alt=""
                 className="img-fluid"
                 src="/images/our-features/arrow-right.svg"
               />
             </Link>
           </div>

           <div className="ourfeaturesbox twostap">
             <div className="icontitle d-flex">
               <figure>
                 <img
                   alt=""
                   className="img-fluid"
                   src="/images/our-features/features1.svg"
                 />
               </figure>
               <strong>
                 <span>Lowest</span> Referral fees
               </strong>
             </div>
             <p>
               On day one, sellers pay up to 70% lower referral fees. Our
               audacious goal is to run on 3%. That’s how&nbsp;
               <label className="demafont">dema</label> becomes your
               frictionless marketplace!
             </p>
             <Link  to="faq#About_Dema/Power_Features">
               learn more
               <img
                 alt=""
                 className="img-fluid"
                 src="/images/our-features/arrow-right.svg"
               />
             </Link>
           </div>


           <div className="ourfeaturesbox onestap">
             <div className="icontitle d-flex">
               <figure>
                 <img
                   alt=""
                   className="img-fluid"
                   src="/images/our-features/features2.svg"
                 />
               </figure>
               <strong>
                 <span>Reduce</span> Returns
               </strong>
             </div>
             <p>
               We incentivize everyone to limit returns and offer sellers the
               option to match with shoppers who have category-specific return
               rate brackets.
             </p>
             <Link to="faq#About_Dema/Power_Features">
               learn more
               <img
                 alt=""
                 className="img-fluid"
                 src="/images/our-features/arrow-right.svg"
               />
             </Link>
           </div>
           
           
           <div className="ourfeaturesbox fourstap">
             <div className="icontitle d-flex">
               <figure>
                 <img
                   alt=""
                   className="img-fluid"
                   src="/images/our-features/features4.svg"
                 />
               </figure>
               <strong>
                 <span>Receive Marketplace</span> Ownership
               </strong>
             </div>
             <p>
               Every transaction earns ownership you don’t pay for. When&nbsp;
               <label className="demafont">dema</label> goes public, this
               ownership means transactions net you even more savings!
             </p>
             <Link to="faq#About_Dema/Power_Features">
               learn more
               <img
                 alt=""
                 className="img-fluid"
                 src="/images/our-features/arrow-right.svg"
               />
             </Link>
           </div>
           <div className="ourfeaturesbox fivestap">
             <div className="icontitle d-flex">
               <figure>
                 <img
                   alt=""
                   className="img-fluid"
                   src="/images/our-features/features5.svg"
                 />
               </figure>
               <strong>
                 <span>Tokens </span> with Utility
               </strong>
             </div>
             <p>
               Use ownership tokens to reduce referral fees, access loans &
               other features. And you get the ability to legislate policies and
               make changes. That’s “Real Power”!
             </p>
             <Link to="faq#About_Dema/Power_Features">
               learn more
               <img
                 alt=""
                 className="img-fluid"
                 src="/images/our-features/arrow-right.svg"
               />
             </Link>
           </div>
         </div>
       </section>

       <Makingyourdecisioneasier />

       <section className="soyou-changes pt pb">
         <div className="container-fluid">
           <h1 className="title1">
             <span>An experience built around the way you work!</span> So you
             don’t have to make changes!
           </h1>
           <div className="soyou-changes-mainbox d-flex">
             <div className="sellers-box">
               <h5>Sellers</h5>
               <div className="shoppic">
                 <img alt="" className="img-fluid" src="/images/sellers.svg" />
               </div>
               <p>
                 You don’t want another store to manage. We get it! What about
                 when shoppers buy? Those orders get sent directly into your
                 existing workflow management systems. We believe in
                 “frictionless”! That way you don’t have to learn new tools.
               </p>
               <a className="readmore" href="/sellers#experience">
                 read more
                 <img
                   alt=""
                   className="img-fluid"
                   src="/images/our-features/arrow-right.svg"
                 />
               </a>
             </div>

             <div className="sellers-box shoppers">
               <h5>Shoppers</h5>
               <div className="shoppic">
                 <img alt="" className="img-fluid" src="/images/shoppers.svg" />
               </div>
               <p>
                 Changing habits can be stressful so we don’t ask you to. Shop
                 the same sites you visit today & we come along to help you
                 save. How? When you checkout and pay through&nbsp;
                 <label className="demafont">dema</label>, you&nbsp;
                 <strong>get a rebate</strong> on any price you see&nbsp;
                 <strong>everytime!</strong> And you get to own&nbsp;
                 <label className="demafont">dema</label> in the process.
               </p>
               <a className="readmore" href="/shoppers#shopper_experience">
                 read more
                 <img
                   alt=""
                   className="img-fluid"
                   src="/images/our-features/arrow-right.svg"
                 />
               </a>
             </div>
           </div>
         </div>
       </section>
       <Connectstore/>
      

       <section className="linkyorstore-main pt">
         <div className="container">
           <div className="linkyorstore-boxmain d-flex">
             <div className="store-picture">
               <img
                 alt=""
                 className="img-fluid"
                 src="/images/store/store1.svg"
               />
             </div>

             <div className="clippath">
               <h2 className="title2">Sellers</h2>
               <div className="linkyorstore-box">
                 <h1 className="title1">
                   <span>Link your store</span> in 5 minutes!
                 </h1>
                 <p>
                   Zilch, Nada, Nothing to pay! Nothing to setup. Signup & meet
                   us live 1:1. We'll show you how easy your listings get
                   onto&nbsp;
                   <label className="demafont bodfont">dema.</label> Plus, you
                   can tell us what else you want!
                 </p>
                 <Link onClick={() => window.scrollTo(0, 0)} to="/sellers#seller_signup" className="btn black">
                 Sign up. It’s free!
                 </Link>
               </div>
             </div>
           </div>
         </div>
       </section>

       <section className="linkyorstore-main shoppers-main pt">
         <div className="container">
           <div className="linkyorstore-boxmain d-flex">
             <div className="store-picture">
               <img
                 alt=""
                 className="img-fluid"
                 src="/images/store/store2.svg"
               />
             </div>
             <div className="clippath">
               <h2 className="title2">Shoppers</h2>
               <div className="linkyorstore-box">
                 <h1 className="title1">
                   <span>Sign up to</span> <figure>buy on&nbsp;
                   <label className="demafont">
                     d<small>e</small>ma<label>!</label>
                   </label>
                   </figure>
                 </h1>
                 <p>
                   Find out what we’ve got in store (a pun?!? 🙀). We’ll send
                   you updates. Brief and just enough to keep you wanting more;
                   pinky promise! 🤙🏼
                 </p>
                 <Link onClick={() => window.scrollTo(0, 0)} to="/shoppers#shopper_signup" className="btn">
                   Buy on <span>dema</span>
                 </Link>
               </div>
             </div>
           </div>
         </div>
       </section>
     </PageLayout>
   );
}
