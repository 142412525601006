import React from 'react';
import { Link } from 'react-router-dom';
import PageLayout from '../../Components/PageLayout'
import Thoughts from '../../Components/Thoughts';


export default function About() {
  return (
    <PageLayout>
      <section className="main-banner innerbannermain">
        <div className="d-flex bannerboxmain">
          <div className="bannerdetails">
            <span className="pagetitle">About Us</span>
            <h1 className="title1">
              <span>Deliver</span> Inexpensive Permissionless Autonomous{" "}
              <span>commerce!</span>
            </h1>

            <span className="point1">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon1.png"
              />
            </span>
            <span className="point2">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon2.png"
              />
            </span>
            <span className="point3">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon3.png"
              />
            </span>
            <span className="point4">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon4.png"
              />
            </span>
            <span className="point5">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon5.png"
              />
            </span>
          </div>

          <div className="banner-pic">
            <figure>
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/aboutus-banner.png"
              />
            </figure>
          </div>
        </div>
      </section>
      <section className="ourmission-main">
        <div className="container ">
          <h1 className="title1">
            <span>Our</span> mission
          </h1>
          <p className="subtitle">
            Build a commerce platform to<br></br> enable your superpowers
          </p>
          <div className="ourmissionbax-main d-flex">
            <div className="ourmissionbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/ourmission-pic1.svg"
              />

              <p>
                Seamlessly use the same<span>marketplaces you do today</span>
              </p>
              <Link className="readmore" to="/faq#About_Dema/Mission">
                learn more
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/our-features/arrow-right.svg"
                />
              </Link>
            </div>

            <div className="ourmissionbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/ourmission-pic2.svg"
              />
              <p>
                Own and govern your <span> marketplace</span>
              </p>
              <Link className="readmore"  to="/faq#About_Dema/Mission">
                learn more
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/our-features/arrow-right.svg"
                />
              </Link>
            </div>

            <div className="ourmissionbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/ourmission-pic3.svg"
              />
              <p>
                Deliver your portable & <span>sovereign reputation</span>
              </p>
              <Link className="readmore" to="/faq#About_Dema/Mission">
                learn more
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/our-features/arrow-right.svg"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="values-main pt">
        <div className="container ">
          <h1 className="title1">
            <span>values</span> @
            <label className="demafont">
              d<small>e</small>ma
            </label>
          </h1>

          <div className="valuesbox-main d-flex">
            <div className="valuesbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/values2.svg"
              />
              <strong>Execution-Focused</strong>
              <p>We get things done quickly. When we fail, we do it fast.</p>
            </div>

            <div className="valuesbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/values1.svg"
              />
              <strong>Unbridled Passion</strong>
              <p>We’re maniacal about removing intermediaries & cost.</p>
            </div>

            <div className="valuesbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/values3.svg"
              />
              <strong>Intuitively Flexible</strong>
              <p>We believe ideas and solutions come from everyone!</p>
            </div>
          </div>
        </div>
      </section>

      <section className="team-main">
        <div className="container ">
          <div className="teaminfo">
            <h1 className="title1">
              <span>Our</span> team
            </h1>
            <p>
            <strong>Remote-first.</strong> Courteous. Direct. We thrive on delivering the
              highest quality work, <strong>meeting often & laughing together!</strong>
            </p>
          </div>
        </div>
      </section>
      <section className="team-members pt">
        <div className="container ">
          <div className="teambox-main d-flex">
            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team1.svg"
                />
              </div>
              <h4 className="title4">Fadi</h4>
              <p>CEO, Co-Founder, Product Wizard</p>
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/fadialrefaee/"
                    target="_blank"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 2.72207C0 2.08364 0.225232 1.55695 0.675676 1.14199C1.12612 0.727011 1.71172 0.519531 2.43243 0.519531C3.14029 0.519531 3.71299 0.723812 4.15058 1.13241C4.60102 1.55377 4.82625 2.10279 4.82625 2.77953C4.82625 3.39241 4.60747 3.90313 4.16988 4.31173C3.71944 4.73309 3.12741 4.94377 2.39382 4.94377H2.37452C1.66666 4.94377 1.09396 4.73309 0.656371 4.31173C0.218784 3.89038 0 3.36048 0 2.72207ZM0.250965 19.4805V6.68665H4.53668V19.4805H0.250965ZM6.9112 19.4805H11.1969V12.3366C11.1969 11.8897 11.2484 11.545 11.3514 11.3024C11.5315 10.8683 11.805 10.5012 12.1718 10.2011C12.5386 9.90108 12.9987 9.75105 13.5521 9.75105C14.9936 9.75105 15.7143 10.7151 15.7143 12.6431V19.4805H20V12.1451C20 10.2554 19.5496 8.82215 18.6486 7.84538C17.7477 6.8686 16.5573 6.38021 15.0772 6.38021C13.417 6.38021 12.1236 7.08885 11.1969 8.50614V8.54444H11.1776L11.1969 8.50614V6.68665H6.9112C6.93693 7.09523 6.94981 8.36567 6.94981 10.498C6.94981 12.6303 6.93693 15.6245 6.9112 19.4805Z"
                        fill="#"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team2.svg"
                />
              </div>
              <h4 className="title4">Venkat</h4>
              <p>COO, Co-Founder, Ops Maniac</p>
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/vsghanta/"
                    target="_blank"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 2.72207C0 2.08364 0.225232 1.55695 0.675676 1.14199C1.12612 0.727011 1.71172 0.519531 2.43243 0.519531C3.14029 0.519531 3.71299 0.723812 4.15058 1.13241C4.60102 1.55377 4.82625 2.10279 4.82625 2.77953C4.82625 3.39241 4.60747 3.90313 4.16988 4.31173C3.71944 4.73309 3.12741 4.94377 2.39382 4.94377H2.37452C1.66666 4.94377 1.09396 4.73309 0.656371 4.31173C0.218784 3.89038 0 3.36048 0 2.72207ZM0.250965 19.4805V6.68665H4.53668V19.4805H0.250965ZM6.9112 19.4805H11.1969V12.3366C11.1969 11.8897 11.2484 11.545 11.3514 11.3024C11.5315 10.8683 11.805 10.5012 12.1718 10.2011C12.5386 9.90108 12.9987 9.75105 13.5521 9.75105C14.9936 9.75105 15.7143 10.7151 15.7143 12.6431V19.4805H20V12.1451C20 10.2554 19.5496 8.82215 18.6486 7.84538C17.7477 6.8686 16.5573 6.38021 15.0772 6.38021C13.417 6.38021 12.1236 7.08885 11.1969 8.50614V8.54444H11.1776L11.1969 8.50614V6.68665H6.9112C6.93693 7.09523 6.94981 8.36567 6.94981 10.498C6.94981 12.6303 6.93693 15.6245 6.9112 19.4805Z"
                        fill="#"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team4.png"
                />
              </div>
              <div className="hiretag">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/hirepic.png"
                />
              </div>
              <h4 className="title4">Is this you?</h4>
              <p>
                Chief Marketplace Architect, Engineering Product Delivery Lead
              </p>
              <a
                href="mailto:buildgreatwithus@dema.shop?subject=Marketplace Technical Expertise"
                className="readmore"
              >
                learn more
                <img
                  alt=""
                  class="img-fluid"
                  src="/images/our-features/arrow-right.svg"
                />
              </a>
            </div>

            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team4.png"
                />
              </div>
              <div className="hiretag">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/hirepic.png"
                />
              </div>
              <h4 className="title4">Is this you?</h4>
              <p>Blockchain Architect, Engineering Product Delivery Lead</p>

              <a
                href="mailto:buildgreatwithus@dema.shop?Subject=Blockchain Technical Expertise"
                className="readmore"
              >
                learn more
                <img
                  alt=""
                  class="img-fluid"
                  src="/images/our-features/arrow-right.svg"
                />
              </a>
            </div>

            {/* <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team5.png"
                />
              </div>
              <h4 className="title4">Name #3</h4>
              <p>
                Chief Marketplace Architect, Engineering Product Delivery Lead
              </p>
              <ul>
                <li>
                  <Link to="#">
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/team-pic/linkdink2.svg"
                    />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team5.png"
                />
              </div>
              <h4 className="title4">Name #4</h4>
              <p>Blockchain Architect, Engineering Product Delivery Lead</p>
              <ul>
                <li>
                  <Link to="#">
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/team-pic/linkdink2.svg"
                    />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team5.png"
                />
              </div>
              <h4 className="title4">Name #5</h4>
              <p>Software Engineer</p>
              <ul>
                <li>
                  <Link to="#">
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/team-pic/linkdink2.svg"
                    />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team5.png"
                />
              </div>
              <h4 className="title4">Name #6</h4>
              <p>Community Marketing Manager</p>
              <ul>
                <li>
                  <Link to="#">
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/team-pic/linkdink2.svg"
                    />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team5.png"
                />
              </div>
              <h4 className="title4">Name #7</h4>
              <p>Marketing Operations</p>
              <ul>
                <li>
                  <Link to="#">
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/team-pic/linkdink2.svg"
                    />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team5.png"
                />
              </div>
              <h4 className="title4">Name #8</h4>
              <p>Customer Success Manager</p>
              <ul>
                <li>
                  <Link to="#">
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/team-pic/linkdink2.svg"
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team5.png"
                />
              </div>
              <h4 className="title4">Name #9</h4>
              <p>Marketing Content Writer</p>
              <ul>
                <li>
                  <Link to="#">
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/team-pic/linkdink2.svg"
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team5.png"
                />
              </div>
              <h4 className="title4">Name #10</h4>
              <p>Marketing Intern</p>
              <ul>
                <li>
                  <Link to="#">
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/team-pic/linkdink2.svg"
                    />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team6.png"
                />
              </div>
              <div className="hiretag">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/hirepic.png"
                />
              </div>
              <h4 className="title4">Open Position</h4>
              <p>Software Engineer Intern</p>
              <ul>
                <li>
                  <Link to="#">
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/team-pic/linkdink2.svg"
                    />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="teambox">
              <div className="member-pic">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/team6.png"
                />
              </div>
              <div className="hiretag">
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/team-pic/hirepic.png"
                />
              </div>
              <h4 className="title4">Open Position</h4>
              <p>Software Engineer Intern</p>
              <ul>
                <li>
                  <Link to="#">
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/team-pic/linkdink2.svg"
                    />
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </section>

      {/* <Thoughts />

      <section className="invester-main">
        <div className="container">
          <div className="titleinvestermain d-flex">
            <div className="titleinvestermain-left">
              <h1 className="title1 hidephonetitle">
                <span>Our</span> investors
              </h1>
              <p>
                We are grateful for the passionate and active venture
                capitalists supporting our journey. Together with our angel
                friends and our patient hurrah-cheering families, we’re building
                great things!
              </p>
            </div>
            <div className="titleinvestermain-right">
              <h1 className="title1 showphonetitle">
                <span>Our</span> investors
              </h1>

              <img
                alt=""
                className="img-fluid"
                src="/images/invester/invester-pic.svg"
              />
            </div>
          </div>

          <div className="investerbox-main d-flex">
            <div className="investerbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/invester/invester1.png"
              />
            </div>

            <div className="investerbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/invester/invester2.png"
              />
            </div>
            <div className="investerbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/invester/invester3.png"
              />
            </div>
            <div className="investerbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/invester/invester4.png"
              />
            </div>
            <div className="investerbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/invester/invester5.png"
              />
            </div>
            <div className="investerbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/invester/invester6.png"
              />
            </div>
            <div className="investerbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/invester/invester7.png"
              />
            </div>
            <div className="investerbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/invester/invester8.png"
              />
            </div>
          </div>
        </div>
      </section> */}
      
    </PageLayout>
  );
}
