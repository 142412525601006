import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import About from '../Pages/About';
import Blog from '../Pages/Blog';
import Blogdetail from '../Pages/Blog-detail';
import Community from '../Pages/Community';
import Faq from '../Pages/Faq';
import Home from '../Pages/Home';
import Privacypolicy from '../Pages/Privacy-policy';
import Refundandcancellationpolicy from '../Pages/Refund-Policy';
import Shippingpolicy from '../Pages/Shipping-Policy';
import Termsofservice from '../Pages/Terms-of-Service';
import Sellers from '../Pages/Sellers';
import Shoppers from '../Pages/Shoppers';
import Watchthepitch from '../Pages/Watchthepitch';



export default function Navigator() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path='community' element={<Community/>} />
      {/* <Route path='blogdetail/:id' element={<Blogdetail/>} /> */}
      {/* <Route path='blog' element={<Blog/>} /> */}
      <Route path='faq' element={<Faq/>} />
      <Route path='shoppers' element={<Shoppers/>} />
      <Route path='sellers' element={<Sellers/>} />
      <Route path='privacypolicy' element={<Privacypolicy/>} />
      <Route path='refundandcancellationpolicy' element={<Refundandcancellationpolicy/>}/>
      <Route path='shippingpolicy' element={<Shippingpolicy/>} />
      <Route path='termsofservice' element={<Termsofservice/>} />
      <Route path='the-short-pitch' element={<Watchthepitch/>} />
      
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
