import React from 'react'
import PageLayout from '../../Components/PageLayout'

export default function shippingpolicy() {
  return (
    <PageLayout>
      <section className="faqbanner pb policiesmain">
        <div className="container">
          <div className="page-maintitle">
            <h1 className="title1">
              <span>Shipping</span> Policy
            </h1>
            <span className="pagetitle">Last Updated: May 26, 2022</span>
          </div>
          <div className="faqround circle1"></div>
          <div className="faqround circle2"></div>
          <div className="faqround circle3"></div>
          <div className="faqround circle4"></div>
          <div className="faqround circle5"></div>
        </div>
      </section>

      <section className="privacypolicy pt">
        <div className="container">
          <h2 className="title2">
            <strong>Shipping Policies</strong>
          </h2>
          <p>
            Shipping policies including rates, estimated times, free shipping
            available or minimum order size for shipping etc., would be
            available to the buyer at the time of order, browsing through
            various listings as well as specified during checkout after a
            product has been added to the cart. Often, users can choose between
            different shipping speeds to get more savings or reduced rates. The
            total shipping & handling cost as well as the estimated date of
            delivery, will be listed under the Order Summary page.
          </p>
          <p>
            The shipping policies, rates, tracking, and delivery are handled by
            sellers and <label className="demafont">dema</label> only indicates
            a suggested shipping policy for the listings to the sellers to make
            the shipping policy convenient for the buyers and the ultimate
            responsibility for shipping and delivery lies with the sellers.
          </p>

          <h2 className="title2 title02">
            <strong>Track Your Package</strong>
          </h2>
          <p>
            Tracking information is provided to buyers along with the order
            details. If an order includes multiple items, each may have separate
            delivery dates and tracking information. Depending on the shipping
            method you chose, it's possible that the tracking information might
            not be visible immediately.
          </p>

          <p>
            <strong>Note:</strong> Some packages, such as standard international
            deliveries, aren't trackable. There could be some other reasons,
            that tracking information is delayed or not available, please wait
            for 1 to 2 days and reach out to customer service to get support on
            this. If the estimated delivery date for your package has passed and
            your tracking information hasn't changed, allow an additional day or
            two for the package to be delivered. If your package shows as
            delivered but you haven't yet received it, please use the contact
            details on carrier contact information that customer service (
            <a href="mailto:hello@dema.shop">hello@dema.shop</a>) provides you
            or listed in the shipment tracking details.
          </p>

          <h2 className="title2 title02">
            <strong>Late Deliveries</strong>
          </h2>

          <p>
            Most packages arrive on time. Orders sometimes show up after the
            estimated delivery date, due to missing / incorrect address (Details
            missing such as Appt #), customs, weather, or any other unforeseen
            conditions. In most cases, the tracking link should give updates
            regarding the delays and specific actions. If the issue can’t be
            resolved with tracking / carrier service within 1-2 days after
            scheduled delivery date, you can reach out to our customer service (
            <a href="mailto:hello@dema.shop">hello@dema.shop</a>) and they will
            help to resolve this.
          </p>
        </div>
      </section>
    </PageLayout>
  );
}
