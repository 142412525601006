import React from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import PageLayout from "../../Components/PageLayout";
import "./style.css";

export default function Watchthepitch() {
  return (
    <PageLayout>
      <section className="investorpitchibanner">
        <div className="d-flex investorpitchi-main">
          <div className="investorpitchileft">
            <h1 className="title1">
              <span>
                Watch the&nbsp;
                <label className="demafont">
                  d<small>e</small>ma
                </label>
                &nbsp;pitch.
              </span>
            </h1>
            <h2 className="title2">Have Q’s & want to learn more?</h2>

            <a target="_blank" href="https://outlook.office365.com/owa/calendar/DemaInvestorDiscussion@getdema.shop/bookings/" className="btn investorpitchibtn">
              schedule meeting
              <img
                alt=""
                class="img-fluid"
                src="/images/our-features/arrow-right.svg"
              />
              <img
                alt=""
                class="img-fluid whitearrow"
                src="/images/arrow-rightwhite.svg"
              />
            </a>
            <span className="point1"></span>
            <span className="point2"></span>
            <span className="point3"></span>
            <span className="point4"></span>
          </div>

          {/* investorpitchiright */}

          <div className="banner-pic">
            <div className="investorpitchicount d-flex">
              <div className="investorbox">
                <strong>
                  <CountUp prefix="$" suffix="B" end={1.1} decimals={1} />
                </strong>
                <p>Waitlisted Seller GMV</p>
              </div>

              <div className="investorbox">
                <strong>
                  <CountUp end={180} duration={3} />
                </strong>
                <p>Sellers in Network</p>
              </div>

              <div className="investorbox investorbox02">
                <strong>
                  <CountUp separator="," end={112954} duration={5} />
                </strong>
                <p>Product Listings</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt">
        <div className="container">
          <div className="investorpitchifullvideo">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/r7LPBX8Sffc?"
              frameborder="0"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}
