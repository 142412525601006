import React, { useEffect, useRef, useState } from "react"
import CountUp from "react-countup"
import "../../commencss/bootstrap.min.css"
import "../../commencss/custom.css"
import "../../commencss/responsive.css"
import PageLayout from "../../Components/PageLayout"
import { Link } from "react-router-dom"
import MailchimpSubscribe from "react-mailchimp-subscribe"

const CustomForm = ({ status, message, onValidated }) => {
  const [error, setShowerror] = useState(false)
  const [showsuccess, setShowsuccess] = useState(false)
  const [user, setUser] = useState({
    email: "",
    firstname: "",
    lastname: "",
    MMERGE5: "",
    businessname: "",
    website: "",
    MMERGE8: ""
  })
  const handleInputs = (e) => {
    let name = e.target.name
    let value = e.target.value
    setUser({ ...user, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onValidated({
      EMAIL: user.email,
      FNAME: user.firstname,
      LNAME: user.lastname,
      MMERGE5: user.MMERGE5,
      MMERGE6: user.businessname,
      MMERGE7: user.website,
      MMERGE8: user.MMERGE8
    })
    if (
      user.email === "" ||
      user.firstname === "" ||
      user.lastname === "" ||
      user.MMERGE5 === "" ||
      user.businessname === "" ||
      user.website === ""
    ) {
      setShowerror(true)
    } else {
      setShowerror(false)
      setUser({
        email: "",
        firstname: "",
        lastname: "",
        MMERGE5: "",
        businessname: "",
        website: "",
        MMERGE8: ""
      })
      setShowsuccess(true)
      setTimeout(() => {
        setShowsuccess(false)
      }, 15000)
    }
  }
  return (
    <form onSubmit={(e) => handleSubmit(e)} noValidate>
      {error ? (
        <text style={{ color: "red", position: "absolute", right: 10, top: 0 }}>
          Please enter required field values.
        </text>
      ) : null}
      <div id="mc_embed_signup_scroll">
        <div className="signupleft">
        <div class="mc-field-group">
          <input
            type="text"
            class="required"
            id="mce-FNAME"
            onChange={handleInputs}
            value={user.firstname}
            name="firstname"
            required
          />
          <label className="form-label" for="mce-FNAME">
            First Name <span class="asterisk">*</span>
          </label>
        </div>
        <div class="mc-field-group mr0">
          <input
            type="text"
            name="lastname"
            onChange={handleInputs}
            class="required"
            id="mce-LNAME"
            required
            value={user.lastname}
          />
          <label for="mce-LNAME">
            Last Name <span class="asterisk">*</span>
          </label>
        </div>
        <div class="mc-field-group">
          <input
            type="text"
            class="required email"
            id="mce-EMAIL"
            onChange={handleInputs}
            value={user.email}
            name="email"
            required
          />
          <label for="mce-EMAIL">
            Email Address <span class="asterisk">*</span>
          </label>
        </div>
        <div class="mc-field-group">
          <input
            type="text"
            class="required"
            id="anything"
            name="MMERGE8"
            required
            onChange={handleInputs}
            value={user.MMERGE8}
          />
          <label for="mce-EMAIL">
          Anything else?
          </label>
        </div>
        </div>
        <div className="signupright">
          <div class="mc-field-group mr0">
            <input
              type="text"
              class="required"
              id="mce-MMERGE6"
              onChange={handleInputs}
              value={user.businessname}
              name="businessname"
              required
            />
            <label for="mce-MMERGE6">
              Business Name <span class="asterisk">*</span>
            </label>
          </div>
          <div class="mc-field-group">
            <input
              type="text"
              class="required"
              id="mce-MMERGE7"
              onChange={handleInputs}
              value={user.website}
              name="website"
              required
            />
            <label for="mce-MMERGE7">
              Your Shop's Website <span class="asterisk">*</span>
            </label>
          </div>
          <div class="mc-field-group input-group">
            <p className="revenue">
              Estimated past 12 month Revenue<span class="asterisk">*</span>
            </p>
            <ul>
              <li>
                <input
                  type="radio"
                  value="&lt;$1M"
                  name="MMERGE5"
                  onChange={handleInputs}
                  id="mce-MMERGE5-0"
                />
                <label for="mce-MMERGE5-0">Up to $1M</label>
              </li>
              <li>
                <input
                  type="radio"
                  value="$1M to $10M"
                  name="MMERGE5"
                  onChange={handleInputs}
                  id="mce-MMERGE5-1"
                />
                <label for="mce-MMERGE5-1">$1M - $10M</label>
              </li>
              <li>
                <input
                  type="radio"
                  value="&gt;$10M"
                  name="MMERGE5"
                  onChange={handleInputs}
                  id="mce-MMERGE5-3"
                />
                <label for="mce-MMERGE5-3">&gt; $10M</label>
              </li>
            </ul>
          </div>
        </div>
        <div style={{ display: "none" }} aria-hidden="true">
          <input
            type="text"
            name="b_26d83d323625f77ff3625c660_54d4f8574d"
            tabindex="-1"
            value=""
          />
        </div>
        <div class="clear">
          <input
            type="submit"
            value="Meet Us"
            name="Meet Us"
            id="mc-embedded-subscribe"
            class="button btn black"
          />
        </div>
        {showsuccess && (
          <p className="massigessucc">
            We are <strong>thrilled to have you on board.</strong> Check your email (and “Junk”
            of course).
          </p>
        )}
      </div>
    </form>
  )
}

const MailchimpForm = (props) => {
  const url = `https://shop.us17.list-manage.com/subscribe/post?u=26d83d323625f77ff3625c660&amp;id=54d4f8574d`
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  )
}

export default function Sellers() {
  const videoRef = useRef()
  useEffect(() => {
    videoRef.current.play()
  }, [])

  useEffect(() => {
    var hashValue = window.location.hash.substr(1)
    if (hashValue == "seller_signup") {
      setTimeout(() => {
        document.getElementById("mc_embed_signup").scrollIntoView({top:"-150px"});
      }, 0);
    } else if (hashValue=="experience"){
      setTimeout(() => {
        document.getElementById("seller_animation").scrollIntoView({top:"-150px"});
      }, 0);
    }
  },
  [])

  // const first = useRef((body) => {
  //   const {'a'} = (this.hash), Link = this.hash;
  //   if (target) {
  //     var targetOffset = $target.offset().top - 92;
  //     (this).click(function(event) {
  //       event.preventDefault();
  //      (scrollElem).animate({scrollTop: targetOffset}, 400, function() {
  //         location.hash = targetOffset;
  //       });
  //   });


  

  return (
    <PageLayout>
      <section className="main-banner innerbannermain">
        <div className="d-flex bannerboxmain">
          <div className="bannerdetails">
            {/* <span className="pagetitle">Seller</span> */}
            <h1 className="title1">
              <span>Supercharge your</span> profits & buy-box wins
            </h1>
            <div className="marketscomprised">
              <p>And offer your customer more savings too!</p>
              <a href="#mc_embed_signup" className="btn black">
                Sign up. It’s free!
              </a>
            </div>
            <span className="point1">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon1.png"
              />
            </span>
            <span className="point2">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon2.png"
              />
            </span>
            <span className="point3">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon3.png"
              />
            </span>
            <span className="point4">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon4.png"
              />
            </span>
            <span className="point5">
              <img
                alt=""
                className="img-fluid"
                src="/images/aboutus-pic/about-icon5.png"
              />
            </span>
          </div>

          <div className="banner-pic">
            <figure>
              <img
                alt=""
                className="img-fluid"
                src="/images/seller/banner-img.png"
              />
            </figure>
          </div>
        </div>
      </section>

      <section className="count fullview pb">
        <div className="container">
          <div className="row count-row bg-white">
            <div className="col-md-4">
              <div className="count-blocks text-center">
                <CountUp separator="," end={112954} />
                <p>SKU’s</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="count-blocks text-center">
                <CountUp end={180} duration={2} />
                <p>Sellers Waitlisted</p>
              </div>
            </div>
            <div className="col-md-4 mb-0">
              <div className="count-blocks text-center br0">
                <CountUp prefix="$" suffix="B" end={1.1} decimals={1} />
                <p>GMV Waitlisted</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="joining" id="seller_animation">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between">
            <div className="sellerpicdetails">
              <h1 className="title1">
                <span>Joining</span>&nbsp;
                <label className="demafont">
                  d<small>e</small>ma
                </label>
                &nbsp;— as easy as drinking your coffee
              </h1>
              <div className="joining-block d-flex">
                <div className="joining-icon me-2">
                  <img src="images/seller/right-arrow.svg" alt="" />
                </div>
                <div className="joining-content">
                  <h5>
                    Make your stores <label className="demafont">dema</label>
                    -ready <span>in 2 easy steps</span>
                  </h5>
                  <p>
                    We’ll guide you how to enter your custom key to connect your
                    store’s inventory. Drink a coffee & we’ll import your
                    products. Presto!
                  </p>
                </div>
              </div>

              <div className="joining-block d-flex">
                <div className="joining-icon me-2">
                  <img
                    src="images/seller/right-arrow.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="joining-content">
                  <h5>
                    Fulfill orders automatically from your existing systems
                  </h5>
                  <p>
                    We sync inventory in real time & automatically relay orders
                    to your fulfillment workflows! You don’t need to learn yet
                    another system.
                  </p>
                </div>
              </div>
            </div>
            <div className="sellerpicvideo">
              <video
                ref={videoRef}
                controls={false}
                loop
                autoplay="autoplay"
                muted
                playsInline
              >
                <source
                  src="https://demawebsite.s3.us-east-2.amazonaws.com/Seller-Animation.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </section>

      <section className="friendly-market pt">
        <div className="container">
          <div className="col-md-12 text-center">
            <div className="section-header d-inline-block text-end">
              <h1 className="title1">
                <span>Most Seller</span> friendly market!
              </h1>
              <p>
                powered by&nbsp;
                <label className="demafont">
                  d<small>e</small>ma
                </label>
              </p>
            </div>
          </div>
          <div className="row direction align-items-center">
            <div className="col-md-6 sellerpic rightpic">
              <img src="images/seller/img-2.svg" alt="" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <div className="friendly-market-content p-0">
                <h3 className="primary-color">A buy-box that beats Amazon’s</h3>
                <p>
                  <label className="demafont">dema</label> picks your products
                  even when Amazon’s buy-box doesn’t. You win more. You increase
                  your sales!
                </p>
                <Link className="readmore" to="/faq#Sellers/Features">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6">
              <div className="friendly-market-content">
                <h3 className="primary-color">Lowest referral fees</h3>
                <p>
                  On day one, sellers pay up to 70% lower referral fees on every
                  category. Our audacious goal is run on 3%. That’s&nbsp;
                  <label className="demafont">dema</label>&nbsp;— your
                  frictionless marketplace!
                </p>
                <Link className="readmore" to="/faq#Sellers/Fees">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 sellerpic">
              <img src="images/seller/img-3.svg" alt="" className="img-fluid" />
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6 sellerpic rightpic">
              <img src="images/seller/img-4.svg" alt="" className="img-fluid" />
            </div>

            <div className="col-md-6">
              <div className="friendly-market-content">
                <h3 className="primary-color">No hidden or other fees</h3>
                <p>
                  No fine print on fees here! Pay ZERO other fees (no listing,
                  subscription, payment processing, advertising, etc...). Just
                  one low referral fee - that’s it!
                </p>
                <Link className="readmore" to="/faq#Sellers/Fees">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
          </div>
          {/* 
          <div className="row direction align-items-center">
            <div className="col-md-6">
              <div className="friendly-market-content p-0">
                <h3 className="primary-color">Transparent unbiased buy-box</h3>
                <p>
                  We publish the buy-box algorithm for everyone to see and
                  shoppers control what choices the buy-box algorithm displays
                  for them.
                </p>
                <Link className="readmore" to="#">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 sellerpic">
              <img src="images/seller/img-5.svg" alt="" className="img-fluid" />
            </div>
          </div> */}

          <div className="row direction align-items-center">
            <div className="col-md-6">
              <div className="friendly-market-content">
                <h3 className="primary-color">
                  Match with low-return Shoppers
                </h3>
                <p>
                  We incentivize low-return shoppers with greater discounts*.
                  And sellers can choose to not match with shoppers who have
                  excessive return rates.
                </p>
                <Link className="readmore" to="/faq#Sellers/Features">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 sellerpic">
              <img src="images/seller/img-6.svg" alt="" className="img-fluid" />
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6 sellerpic rightpic">
              <img src="images/seller/img-7.svg" alt="" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <div className="friendly-market-content p-0">
                <h3 className="primary-color">Customer Service on Autopilot</h3>
                <p>
                  <label className="demafont">dema</label>&nbsp;manages customer
                  service for your orders 24x7 and automates returns management.
                </p>
                <Link className="readmore" to="/faq#Sellers/Support_&_Policies">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6">
              <div className="friendly-market-content">
                <h3 className="primary-color">Own your marketplace</h3>
                <p>
                  Every sale on&nbsp;<label className="demafont">dema</label>
                  &nbsp;earns you ownership tokens which you can use to lower
                  referral fees, get working capital financing, create & vote on
                  proposals, etc...
                </p>
                <Link className="readmore" to="/faq#Sellers/Features">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 sellerpic">
              <img src="images/seller/img-8.svg" alt="" className="img-fluid" />
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6 sellerpic rightpic">
              <img src="images/seller/img-9.svg" className="img-fluid" alt="" />
            </div>
            <div className="col-md-6">
              <div className="friendly-market-content p-0">
                <h3 className="primary-color">Your customer always</h3>
                <p>
                  No matter your size, our tools enable you to create loyalty
                  programs so you can convert repeat shoppers into your social
                  tribe.
                </p>
                <Link className="readmore" to="/faq#Sellers/Support_&_Policies">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6">
              <div className="friendly-market-content">
                <h3 className="primary-color">Shoppers save on every order</h3>
                <p>
                  Customers receive a discount on every purchase they make
                  on&nbsp;<label className="demafont">dema</label>&nbsp;and they
                  get&nbsp;<label className="demafont">dema</label>&nbsp;tokens
                  as well! That’s even more savings.
                </p>
                <Link className="readmore" to="/faq#Sellers/Features">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 sellerpic">
              <img src="images/seller/img-10.svg" alt="" />
            </div>
          </div>

          <div className="row direction align-items-center">
            <div className="col-md-6 sellerpic rightpic">
              <img src="images/seller/img-11.svg" alt="" />
            </div>
            <div className="col-md-6">
              <div className="friendly-market-content p-0">
                <h3 className="primary-color">
                  <label className="demafont">dema</label> doesn’t copy your
                  products
                </h3>
                <p>
                  Unlike other marketplaces, we don’t copy your products or
                  launch private labels to compete with you. Sell & grow
                  on&nbsp;<label className="demafont">dema</label>
                  &nbsp;confidently.
                </p>
                <Link className="readmore" to="/faq#Sellers/Support_&_Policies">
                  learn more
                  <img
                    alt=""
                    className="img-fluid"
                    src="/images/our-features/arrow-right.svg"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="seller-experience pt pb">
        <div className="container">
          <div className="section-header">
            <h1 className="title1">
              <span>Seller</span> experiences
            </h1>
          </div>
          <div className="row">
            <div className="experience-block">
              <div className="d-flex align-items-center mb-4">
                <img
                  src="images/gianaicon.svg"
                  alt=""
                  width="40"
                  className="me-1 me-lg-3"
                />
                <h5 className="fw-bold m-0 primary-color">Giana Kenter</h5>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen.
              </p>
              <ul>
                <li>
                  <img
                    src="images/seller/audioicon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </li>

                <li>
                  <img
                    src="images/seller/videoicon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </li>
              </ul>
            </div>
            <div className="experience-block">
              <div className="d-flex align-items-center mb-4">
                <img
                  src="images/gianaicon.svg"
                  alt=""
                  width="40"
                  className="me-1 me-lg-3"
                />
                <h5 className="fw-bold m-0 primary-color">Hanna Septimus</h5>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen.
              </p>
              <ul>
                <li>
                  <img
                    src="images/seller/audioicon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </li>

                <li>
                  <img
                    src="images/seller/videoicon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </li>
              </ul>
            </div>
            <div className="experience-block">
              <div className="d-flex align-items-center mb-4">
                <img
                  src="images/gianaicon.svg"
                  alt=""
                  width="40"
                  className="me-1 me-lg-3"
                />
                <h5 className="fw-bold m-0 primary-color">Maren Philips</h5>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen.
              </p>
              <ul>
                <li>
                  <img
                    src="images/seller/audioicon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </li>

                <li>
                  <img
                    src="images/seller/videoicon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="seller-experience pb">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header text-center">
                <h1 className="title1">
                  <span>Starightforward</span> Onboarding!
                </h1>
                <p className="mt-0 signupgrt">(sign up to get started)</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="boost position-relative" id="mc_embed_signup"> 
        <div className="container">
          <div className="row align-items-center boostyourprofits">
            <div className="col-md-4">
              <h1 className="title1">
                <span> Get on</span>&nbsp;
                <label className="demafont">
                  d<small>e</small>ma&nbsp;
                </label>
                instantly.
              </h1>
              <p>
                See a live demo & let&nbsp;
                <strong>our team on-board you in 5 minutes.</strong>
              </p>
            </div>

            <div className="col-md-8">
              <div className="form-area mt-4 mt-md-0">
                <MailchimpForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}
