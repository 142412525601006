import React, { useContext, useEffect, useState } from "react";
import PageLayout from "../../Components/PageLayout";
import Faqdata from "../../Components/Faqdata";
import { BlogsContext } from "../../Contexts/BlogsContext";

export default function Faq() {
  class Accordion extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        activedIndex: this.getID(),
        acdTransition: false,
      };
    }
  
    getID() {
      let expandedIndex = [];
      let children = this.props.children;
  
      React.Children.map(children, (items, i) => {
        if (items.props.expanded) {
          expandedIndex.push(items.props.id);
        }
      });
  
      return expandedIndex;
    }
  
    addTransition() {
      if (this.state.acdTransition === true) {
        return "acd-transition";
      } else {
        return "";
      }
    }
  
    handleClick(acdID) {
      let muitipleOpen = this.props.muitipleOpen;
      let activedList = [...this.state.activedIndex];
      let activedItem = this.state.activedIndex.indexOf(acdID);
  
      if (muitipleOpen) {
        if (activedItem !== -1) {
          activedList.splice(activedItem, 1);
          this.setState({ activedIndex: activedList });
        } else {
          this.setState({ activedIndex: [...activedList, acdID] });
        }
      } else {
        if (activedItem !== -1) {
          activedList.splice(activedItem, 1);
          this.setState({ activedIndex: activedList });
        } else {
          this.setState({ activedIndex: [acdID] });
        }
      }
  
      if (this.state.acdTransition === false) {
        this.setState({ acdTransition: true });
      }
    }
  
    isExpanded(acdID) {
      if (this.state.activedIndex.includes(acdID)) {
        return "actived";
      } else {
        return "";
      }
    }
  
    render() {
      let childArr = this.props.children;
  
      if (childArr.length === undefined) {
        childArr = [this.props.children];
      }
  
      const items = childArr.map((child, i) => {
        //let newIndex = i + 1;
        return React.cloneElement(child, {
          isExpanded: this.isExpanded.bind(this),
          handleClick: this.handleClick.bind(this),
          addTransition: this.addTransition.bind(this),
        });
      });
  
      return <div className={`accordion-box`}>{items}</div>;
    }
  }
  class AccordionList extends React.Component {
    render() {
      return (
        <div
          className={`accordion-list ${this.props.isExpanded(
            this.props.id
          )} ${this.props.addTransition()}`}
        >
          <div
            className={`accordion-label`}
            onClick={() => {
              this.props.handleClick(this.props.id);
            }}
          >
            {this.props.headTitle} <span className="acd-arrow"></span>
          </div>
          <div className={`accordion-content`}>
            <div className="accordion-inner">{this.props.children}</div>
          </div>
        </div>
      );
    }
  }
  const { faq } = useContext(BlogsContext);
  const [selectedSections, setSelectedSections] = useState({})
  useEffect(() => {
    let sectionName = ''
    let subSection = ''
    if (faq[0] && faq[0].attributes.subsection) {
      sectionName = faq[0].attributes.faq_section
      subSection = faq[0].attributes.subsection[0].title
    } else if (faq[0] && faq[0].attributes.faq_section) {
      sectionName = faq[0].attributes.faq_section;
    }
    var hashValue = window.location.hash.substr(1)
    if(hashValue){
      let decodedData = decodeURI(hashValue)
      let selectionFromUrl = decodedData.replace(/_/g, ' ');
      if(selectionFromUrl){
        const sec_subsec = selectionFromUrl.split("/");
        sectionName = sec_subsec[0];
        subSection = sec_subsec[1];
      }
    }
    setSelectedSections({ section: sectionName, subSection: subSection });
  }, [faq]);

  return (
    <PageLayout>
      <section className="faqbanner">
        <div className="container">
          <div className="page-maintitle">
            {/* <span className="pagetitle">Faq</span> */}
            <h1 className="title1">
              <span>You have</span> questions.<br></br>
              <span> we</span> give you answers!
            </h1>
          </div>

          <div className="faqpicqustion tag1">
            <img alt="" className="img-fluid" src="/images/faq/qustion1.svg" />
          </div>
          <div className="faqpicqustion tag2">
            <img alt="" className="img-fluid" src="/images/faq/qustion2.svg" />
          </div>
          <div className="faqpicqustion tag3">
            <img alt="" className="img-fluid" src="/images/faq/qustion3.svg" />
          </div>
          <div className="faqpicqustion tag4">
            <img alt="" className="img-fluid" src="/images/faq/qustion4.svg" />
          </div>
          <div className="faqround circle1"></div>
          <div className="faqround circle2"></div>
          <div className="faqround circle3"></div>
          <div className="faqround circle4"></div>
          <div className="faqround circle5"></div>

          {/* <div className="searchbar">
            <span>
              <img
                alt=""
                className="img-fluid"
                src="/images/blog-pic/blogsearch.svg"
              />
            </span>
            <input type="text" placeholder="Search for anything..." />
          </div> */}
        </div>
      </section>
      <div style={{ clear: "both" }}></div>
      <Faqdata />
      <section className="mobilfaquse pt">
        <div className="container">
          <Accordion muitipleOpen={false}>
            {faq.map((faqObj) => {
              return (
                <AccordionList
                  expanded={
                    selectedSections.section == faqObj.attributes.faq_section
                      ? true
                      : false
                  }
                  id={faqObj.attributes.faq_section}
                  key={faqObj.attributes.faq_section}
                  headTitle={faqObj.attributes.faq_section}
                >
                  {faqObj.attributes.subsection &&
                  faqObj.attributes.subsection.length > 0 ? (
                    <Accordion muitipleOpen={false}>
                      {faqObj.attributes.subsection.map((subsection) => {
                        return (
                          <AccordionList
                            expanded={
                              selectedSections.subSection == subsection.title
                                ? true
                                : false
                            }
                            id={subsection.id}
                            key={subsection.id}
                            headTitle={subsection.title}
                          >
                            <div className="faq-detailsscroll">
                              {subsection.qa ? (
                                subsection.qa.map((qasAns) => {
                                  return (
                                    <div className="faq-qustionans">
                                      <p>
                                        <strong>{qasAns.question}</strong>
                                      </p>
                                      <p>{qasAns.answer}</p>
                                    </div>
                                  );
                                })
                              ) : (
                                <div />
                              )}
                            </div>
                          </AccordionList>
                        );
                      })}
                    </Accordion>
                  ) : (
                    <div className="faq-detailsscroll">
                      {faqObj.attributes.qa ? (
                        faqObj.attributes.qa.map((qasAns) => {
                          return (
                            <div className="faq-qustionans">
                              <p>
                                <strong>{qasAns.question}</strong>
                              </p>
                              <p>{qasAns.answer}</p>
                            </div>
                          );
                        })
                      ) : (
                        <div />
                      )}
                    </div>
                  )}
                </AccordionList>
              );
            })}
          </Accordion>
        </div>
      </section>
    </PageLayout>
  );
}

