import React from 'react'
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';


export default function Makingyourdecisioneasier() {
   return (
    <section className="makingyour-main pt">
      <div className="container">
        <h1 className="title1">
          <span>Making your decision</span> easier
        </h1>
        <div className="subtitles">
          <p>
            We studied every problem “successful” marketplaces eventually
            created for their sellers and shoppers. We want your journey
            with&nbsp;<label className="demafont">dema</label> to start with
            you&nbsp;<strong>owning your marketplace.</strong> Everything you
            see here is as “forever” as you decide it to be; no “bait & switch”
            gimmicks!
          </p>
        </div>
        <div className="makingyour-tablemain">
          <div className="maintablefirst">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <div className="mobilelogo">
                      <img
                        alt=""
                        className="img-fluid"
                        src="/images/table/mobilelogo1.png"
                      />
                    </div>
                    <div className="destoplogo">
                      <img
                        alt=""
                        className="img-fluid"
                        src="/images/bannerslide-logo/demalogo.svg"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="mobilelogo">
                      <img
                        alt=""
                        className="img-fluid"
                        src="/images/table/mobilelogo2.png"
                      />
                    </div>
                    <div className="destoplogo">
                      <img
                        alt=""
                        className="img-fluid"
                        src="/images/bannerslide-logo/amazonlogo.svg"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="mobilelogo">
                      <img
                        alt=""
                        className="img-fluid"
                        src="/images/table/mobilelogo3.png"
                      />
                    </div>
                    <div className="destoplogo">
                      <img
                        alt=""
                        className="img-fluid"
                        src="/images/bannerslide-logo/walmart.svg"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="mobilelogo">
                      <img
                        alt=""
                        className="img-fluid"
                        src="/images/table/mobilelogo4.png"
                      />
                    </div>
                    <div className="destoplogo">
                      <img
                        alt=""
                        className="img-fluid"
                        src="/images/bannerslide-logo/ebay.svg"
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Lowest Referral Fees
                    <Popup
                      trigger={
                        <Link to="#">
                          <img
                            alt=""
                            className="img-fluid"
                            src="/images/table/qustion.svg"
                          />
                        </Link>
                      }
                      position={"top center"}
                      offsetX={4}
                      closeOnDocumentClick
                    >
                      <div className='nofees'>
                      As a seller, pay one fee (7%). Compare&nbsp;
                      <label className="demafont">dema</label>‘s fee vs the
                      total fees of other marketplaces.
                      <Link to="/faq#Sellers/Fees" className="readmore">
                        learn more
                        <img
                          alt=""
                          className="img-fluid"
                          src="/images/our-features/arrow-right.svg"
                        />
                      </Link>
                      </div>
                    </Popup>
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    No Processing Fees
                    <Popup
                      trigger={
                        <Link to="#">
                          <img
                            alt=""
                            className="img-fluid"
                            src="/images/table/qustion.svg"
                          />
                        </Link>
                      }
                      position={"top center"}
                      offsetX={5}
                      closeOnDocumentClick
                    >
                      <div className='nofees'>
                      The fee other marketplaces charge for taking a customer’s
                      various payments.
                      <Link to="/faq#Sellers/Fees" className="readmore">
                        learn more
                        <img
                          alt=""
                          className="img-fluid"
                          src="/images/our-features/arrow-right.svg"
                        />
                      </Link>
                      </div>
                    </Popup>
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/doller.svg"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    No Advertising Fees
                    <Popup
                      trigger={
                        <Link to="#">
                          <img
                            alt=""
                            className="img-fluid"
                            src="/images/table/qustion.svg"
                          />
                        </Link>
                      }
                      position={"top center"}
                      offsetX={5}
                      closeOnDocumentClick
                    ><div className='nofees'>
                      The fee you pay elsewhere to advertise & compete to show
                      up in results.
                      <Link to="/faq#Sellers/Fees" className="readmore">
                        learn more
                        <img
                          alt=""
                          className="img-fluid"
                          src="/images/our-features/arrow-right.svg"
                        />
                      </Link>
                      </div>
                    </Popup>
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/doller.svg"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    No competition from the marketplace
                    <Popup
                      trigger={
                        <Link to="#">
                          <img
                            alt=""
                            className="img-fluid"
                            src="/images/table/qustion.svg"
                          />
                        </Link>
                      }
                      position={"top center"}
                      offsetX={5}
                      closeOnDocumentClick
                    ><div className='nofees'>
                      By contract,&nbsp;<label className="demafont">dema</label>{" "}
                      doesn’t copy or re-sell products you’ve listed in your
                      store.
                      <Link to="/faq#Sellers/Support_&_Policies" className="readmore">
                        learn more
                        <img
                          alt=""
                          className="img-fluid"
                          src="/images/our-features/arrow-right.svg"
                        />
                      </Link>
                      </div>
                    </Popup>
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    No Recurring Fees
                    <Popup
                      trigger={
                        <Link to="#">
                          <img
                            alt=""
                            className="img-fluid"
                            src="/images/table/qustion.svg"
                          />
                        </Link>
                      }
                      position={"top center"}
                      offsetX={5}
                      closeOnDocumentClick
                    >
                      <div className='nofees'>
                      Other maketplaces charge recurring fees justifying them as
                      fixed overhead costs.
                      <Link to="/faq#Sellers/Fees" className="readmore">
                        learn more
                        <img
                          alt=""
                          className="img-fluid"
                          src="/images/our-features/arrow-right.svg"
                        />
                      </Link>
                      </div>
                    </Popup>
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    No Listing Fees
                    <Popup
                      trigger={
                        <Link to="#">
                          <img
                            alt=""
                            className="img-fluid"
                            src="/images/table/qustion.svg"
                          />
                        </Link>
                      }
                      position={"top center"}
                      offsetX={5}
                      closeOnDocumentClick
                    >
                       <div className='nofees'>
                      Some marketplaces charge to list your store’s contents.
                      List with&nbsp;<label className="demafont">dema</label>{" "}
                      for free.
                      <Link to="/faq#Sellers/Fees" className="readmore">
                        learn more
                        <img
                          alt=""
                          className="img-fluid"
                          src="/images/our-features/arrow-right.svg"
                        />
                      </Link></div>
                    </Popup>
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/doller.svg"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Buy-Box you can see and change
                    <Popup
                      trigger={
                        <Link to="#">
                          <img
                            alt=""
                            className="img-fluid"
                            src="/images/table/qustion.svg"
                          />
                        </Link>
                      }
                      position={"top center"}
                      offsetX={5}
                      closeOnDocumentClick
                    >
                      <div className='nofees'>
                      We publish all algorithm rules so you understand how to
                      best compete with others, not us.
                      <Link to="faq#Sellers/Features" className="readmore">
                        learn more
                        <img
                          alt=""
                          className="img-fluid"
                          src="/images/our-features/arrow-right.svg"
                        />
                      </Link>
                      </div>
                    </Popup>
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Shoppers save on every purchase
                    <Popup
                      trigger={
                        <Link to="#">
                          <img
                            alt=""
                            className="img-fluid"
                            src="/images/table/qustion.svg"
                          />
                        </Link>
                      }
                      position={"top center"}
                      offsetX={5}
                      closeOnDocumentClick
                    >
                      <div className='nofees'>
                      Shoppers get 4% on every purchase; an incentive to
                      experience the value&nbsp;
                      <label className="demafont">dema</label> delivers.
                      <Link to="/faq#Sellers/Features" className="readmore">
                        learn more
                        <img
                          alt=""
                          className="img-fluid"
                          src="/images/our-features/arrow-right.svg"
                        />
                      </Link>
                      </div>
                    </Popup>
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Receive ownership when you buy or sell
                    <Popup
                      trigger={
                        <Link to="#">
                          <img
                            alt=""
                            className="img-fluid"
                            src="/images/table/qustion.svg"
                          />
                        </Link>
                      }
                      position={"top center"}
                      offsetX={5}
                      closeOnDocumentClick
                    >
                       <div className='nofees'>
                      Receive ownership tokens without paying for them. Simply
                      buy or sell on&nbsp;
                      <label className="demafont">dema</label>.
                      <Link to="/faq#Sellers/Features" className="readmore">
                        learn more
                        <img
                          alt=""
                          className="img-fluid"
                          src="/images/our-features/arrow-right.svg"
                        />
                      </Link>
                      </div>
                    </Popup>
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Govern the marketplace
                    <Popup
                      trigger={
                        <Link to="#">
                          <img
                            alt=""
                            className="img-fluid"
                            src="/images/table/qustion.svg"
                          />
                        </Link>
                      }
                      position={"top center"}
                      offsetX={5}
                      closeOnDocumentClick
                    >
                      <div className='nofees'>
                      Use your tokens to create & vote on ballots to change
                      rules & policies @<label className="demafont">dema</label>
                      .
                      <Link to="/faq#Sellers/Features" className="readmore">
                        learn more
                        <img
                          alt=""
                          className="img-fluid"
                          src="/images/our-features/arrow-right.svg"
                        />
                      </Link>
                      </div>
                    </Popup>
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/done.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                  <td>
                    <img
                      alt=""
                      className="img-fluid"
                      src="/images/table/close.svg"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
  
}



