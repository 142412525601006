import React, { useState } from "react"
import { Link } from "react-router-dom"
import CountUp from "react-countup"
import PageLayout from "../../Components/PageLayout"
import Thoughts from "../../Components/Thoughts"
import MailchimpSubscribe from "react-mailchimp-subscribe"

const CustomForm = ({ status, message, onValidated }) => {
  const [showsuccess, setShowsuccess] = useState(false)
  const [user, setUser] = useState({
    EMAIL: "",
  })
  const handleInputs = (e) => {
    let name = e.target.name
    let value = e.target.value
    setUser({ ...user, [name]: value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    //Need to handle subscribe
    onValidated({
      EMAIL: user.EMAIL,
    })
    setUser({
      EMAIL: "",
    })
    setShowsuccess(true)
    setTimeout(() => {
      setShowsuccess(false)
    }, 15000)
  }
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <h1 className="title1">
        <span>Subscribe</span> to our newsletter
      </h1>
      <div id="mc_embed_signup_scroll">
        <div className="subscribeinputfiled mc-field-group">
          <span id="mc-embedded-subscribe">
            <img
              onClick={handleSubmit}
              alt=""
              className="img-fluid"
              src="/images/our-features/arrow-right.svg"
            />
          </span>
          <input
            type="email"
            name="EMAIL"
            onChange={handleInputs}
            class="required email"
            value={user.EMAIL}
            id="mce-EMAIL"
            required
            placeholder="your email address"
          />
        </div>
        <div style={{ clear: "both" }}></div>
        {showsuccess && (
          <p>
            We've sent you a note; you know the drill: Check spam, Confirm to
            subscribe so we're not labeled junk, etc...
          </p>
        )}
      </div>
    </form>
  )
}

const MailchimpForm = (props) => {
  const url = `https://shop.us17.list-manage.com/subscribe/post?u=26d83d323625f77ff3625c660&amp;id=e9ca2e2847`
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  )
}

export default function Community() {
  return (
    <PageLayout>
      <section className="main-banner innerbannermain communitypage">
        <div className="d-flex bannerboxmain">
          <div className="bannerdetails">
            <span className="pagetitle">Community</span>
            <h1 className="title1">
              <span>A marketplace</span> built <span>on open ideas</span> by
              everyone!
              {/* <div className="subtitle">Your thought counts!</div> */}
            </h1>
            <div className="marketscomprised">
              <p>
                We know a lot but <strong>we don't know everything!</strong>
                &nbsp;Learn about&nbsp;
                <strong>
                  <label className="demafont">dema</label>, teach us&nbsp;
                </strong>
                and others what we might have missed. Become your own greatest
                advocate. <strong className="together">Let’s do it together!</strong>
              </p>
            </div>

            <span className="point1"></span>
            <span className="point2"></span>
            <span className="point3"></span>
            <span className="point4"></span>
          </div>

          <div className="banner-pic">
            <figure>
              <img
                alt=""
                className="img-fluid"
                src="/images/community/banner-pic.png"
              />
            </figure>
          </div>
        </div>
      </section>

      <section className="developers-main">
        <div className="container">
          <h1 className="title1">
            developer, seller, shopper, everyone...
            <span>we’d love to hear from you!</span>
          </h1>
          <div className="developersbox-main d-flex">
            <div className="developersbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/community/developers1.svg"
              />
              <strong>Email? Sure</strong>
              <a href="mailto:hello@dema.shop" className="readmore">
                contact us
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/our-features/arrow-right.svg"
                />
              </a>
            </div>
            <div className="developersbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/community/developers2.svg"
              />
              <strong>Discuss your ideas!</strong>
              <a
                target="_blank"
                href="https://discord.gg/hj95eYEnUr"
                className="readmore"
              >
                jump onto Discord
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/our-features/arrow-right.svg"
                />
              </a>
            </div>
            <div className="developersbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/community/developers3.svg"
              />
              <strong>Prefer Tweets? </strong>
              <a
                target="_blank"
                href="https://twitter.com/demadotshop"
                className="readmore"
              >
                take the podium
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/our-features/arrow-right.svg"
                />
              </a>
            </div>
            <div className="developersbox">
              <img
                alt=""
                className="img-fluid"
                src="/images/community/developers4.svg"
              />
              <strong>Meet us live. </strong>
              <a
                target="_blank"
                href="https://discord.com/events/999193290895278211/1004282267650240524"
                className="readmore"
              >
                join our weekly call
                <img
                  alt=""
                  className="img-fluid"
                  src="/images/our-features/arrow-right.svg"
                />
              </a>
            </div>
          </div>
        </div>

        {/* <div className="customers-main">
          <div className="container">
            <div className="customersbox-main d-flex">
              <div className="customersbox">
                <CountUp suffix="K+" end={5} />
                <p>Customers</p>
              </div>
              <div className="customersbox">
                <CountUp end={600} duration={2} />
                <p>Sellers</p>
              </div>
              <div className="customersbox">
                <CountUp suffix="M+" end={5} duration={3} />
                <p>SKU’s</p>
              </div>
              <div className="customersbox">
                <CountUp suffix="K+" end={10} duration={4} />
                <p>Community</p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="subscribe-main">
          <div className="container" id="mc_embed_signup">
            <MailchimpForm />
          </div>
        </div>
      </section>
      {/* <div className="bober-bottemhide">
        <Thoughts />
      </div> */}
    </PageLayout>
  );
}
