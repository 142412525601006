import React from 'react'
import { Link } from 'react-router-dom';
import PageLayout from '../../Components/PageLayout'

export default function privacypolicy() {
  return (
    <PageLayout>
      <section className="faqbanner pb policiesmain">
        <div className="container">
          <div className="page-maintitle">
            <h1 className="title1">
              <span>Privacy</span> Policy
            </h1>
            <span className="pagetitle">
              Last Updated: Aug 8, 2022<br></br> Effective Date: Aug 8, 2022
            </span>
          </div>
          <div className="faqround circle1"></div>
          <div className="faqround circle2"></div>
          <div className="faqround circle3"></div>
          <div className="faqround circle4"></div>
          <div className="faqround circle5"></div>
        </div>
      </section>

      <section className="privacypolicy pt">
        <div className="container">
          <h2 className="title2">
            <strong>Introduction</strong>
          </h2>
          <p>
            Welcome to&nbsp;
            <strong>
              <label className="demafont">dema</label> Platforms Corp.
            </strong>
          </p>
          <p>
            <strong>
              <label className="demafont">dema</label> Platforms Corp.
            </strong>
            &nbsp;(<label className="demafont">“dema”</label>, “us”, “we”, or
            “our”) operates&nbsp;
            <Link to="/">https://dema.shop/</Link>,&nbsp;
            <label className="demafont">dema</label> browser extensions,&nbsp;
            <label className="demafont">dema</label> Mobile applications
            (hereinafter referred to as <strong>“Service”</strong>).
          </p>
          <p>
            Our Privacy Policy governs your visit to&nbsp;
            <Link to="/">https://dema.shop/</Link> and&nbsp;
            <label className="demafont">dema</label>
            &nbsp;browsers extension <label className="demafont">dema</label>
            &nbsp;mobile applications, and explains how we collect, safeguard
            and disclose information that results from your use of our Service.
          </p>
          <p>
            We use your data to provide and improve Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions.
          </p>
          <p>
            Our Terms and Conditions <strong>(“Terms”)</strong> govern all use
            of our Service and together with the Privacy Policy constitutes your
            agreement with us&nbsp;
            <strong>(“agreement”)</strong>.
          </p>
          <div className="title2 title02">
            <strong>Definitions</strong>
          </div>
          <p>
            <strong>SERVICE</strong> means the&nbsp;
            <Link to="/">https://dema.shop/</Link>
            &nbsp;website and <label className="demafont">dema</label> browsers
            extension, <label className="demafont">dema</label> mobile
            applications operated by <label className="demafont">dema</label>
            &nbsp;Platforms Corp.
          </p>

          <p>
            <strong>PERSONAL DATA</strong> means data about a living individual
            who can be identified from those data (or from those and other
            information either in our possession or likely to come into our
            possession).
          </p>
          <p>
            <strong>USAGE DATA</strong> is data collected automatically either
            generated by the use of Service or from Service infrastructure
            itself (for example, the duration of a page visit).
          </p>
          <p>
            <strong>COOKIES</strong> are small files stored on your device
            (computer or mobile device).
          </p>
          <p>
            <strong>DATA CONTROLLER</strong> means a natural or legal person who
            (either alone or jointly or in common with other persons) determines
            the purposes for which and the manner in which any personal data
            are, or are to be, processed. For the purpose of this Privacy
            Policy, we are a Data Controller of your data.
          </p>

          <p>
            <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any
            natural or legal person who processes the data on behalf of the Data
            Controller. We may use the services of various Service Providers to
            process your data more effectively.
          </p>
          <p>
            <strong>DATA SUBJECT</strong> s any living individual who is the
            subject of Personal Data.
          </p>
          <p>
            <strong>THE USER</strong> is the individual using our Service. The
            User corresponds to the Data Subject, who is the subject of Personal
            Data.
          </p>

          <div className="title02 title2">
            <strong>Information Collection and Use</strong>
          </div>
          <p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>
          <div className="title02 title2">
            <strong>Types of Data Collected, Personal Data</strong>
          </div>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (“<strong>Personal Data</strong>”). Personally
            identifiable information may include, but is not limited to:
          </p>

          <ol type="a">
            <li style={{ paddingBottom: "5px" }}>
              User information, including:
              <ol type="i">
                <li>Email address;</li>
                <li>First name and last name;</li>
                <li>Phone number;</li>
                <li>Address, state, province, ZIP/postal code, city;</li>
                <li>
                  Your name and email address when you engage with us for email
                  support.
                </li>
                <li>Your wallet address</li>
              </ol>
            </li>
            <li style={{ paddingBottom: "5px" }}>
              Information from email messages stored in or sent to the email
              accounts you provide to us, or that you otherwise provide to us,
              including:
              <ol type="i">
                <li>
                  Order information, including any shipping and/or billing
                  addresses;
                </li>
                <li>Product information, including names and unit counts;</li>
                <li>
                  Any associated reference identification in relation to your
                  order, such as an order tracking number.
                </li>
              </ol>
            </li>
            <li>
              Any other Personal Data where you have given us your express
              permission.
            </li>
          </ol>

          <p>
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of
            these communications from us by following the unsubscribe link or by
            emailing at <a href="mailto:hello@dema.shop">hello@dema.shop</a>.
          </p>

          <p>
            <strong>Usage Data</strong>
            <br></br>
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through a mobile
            device <br></br> <strong>(“Usage Data”)</strong>.
          </p>
          <p>
            This Usage Data may include information such as your computer's
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
          <p>
            When you access Service with a mobile device, this Usage Data may
            include information such as the type of mobile device you use, your
            mobile device unique ID, the IP address of your mobile device, your
            mobile operating system, the type of mobile Internet browser you
            use, unique device identifiers and other diagnostic data.
          </p>
          <p>
            <strong>Tracking Cookies Data</strong>
            <br></br>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>

          <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service.
          </p>

          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>

          <p>Examples of Cookies we use:</p>
          <ol type="a">
            <li>
              <strong>Session Cookies:</strong> We use Session Cookies to
              operate our Service.
            </li>
            <li>
              <strong>Preference Cookies</strong> We use Preference Cookies to
              remember your preferences and various settings
            </li>
            <li>
              <strong>Security Cookies:</strong> We use Security Cookies for
              security purposes.
            </li>
            <li>
              <strong>Advertising Cookies:</strong> Advertising Cookies are used
              to serve you with advertisements that may be relevant to you and
              your interests
            </li>
          </ol>
          <p>
            <strong>Other Data</strong>
            <br></br> While using our Service, we may also collect the following
            information: sex, age, date of birth, place of birth, passport
            details, citizenship, registration at place of residence and actual
            address, telephone number (work, mobile), details of documents on
            education, qualification, professional training, employment
            agreements, non-disclosure agreements, information on bonuses and
            compensation, information on marital status, family members, social
            security (or other taxpayer identification) number, office location
            and other data.
          </p>

          <div className="title02 title2">
            <strong>Use of Data</strong>
          </div>
          <p>
            <label className="demafont">dema.</label> uses the collected data
            for various purposes:
          </p>
          <ol type="a">
            <li>to provide and maintain our Service;</li>
            <li>to notify you about changes to our Service;</li>
            <li>
              to allow you to participate in interactive features of our Service
              when you choose to do so;
            </li>
            <li>to provide customer support;</li>
            <li>
              to gather analysis or valuable information so that we can improve
              our Service;
            </li>
            <li>to monitor the usage of our Service;</li>
            <li>to detect, prevent and address technical issues;</li>
            <li>to fulfill any other purpose for which you provide it;</li>
            <li>
              to carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection;
            </li>
            <li>
              to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc.;
            </li>
            <li>
              to provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information;
            </li>
            <li>
              in any other way we may describe when you provide the information;
            </li>
            <li>for any other purpose with your consent.</li>
          </ol>

          <div className="title02 title2">
            <strong>Retention of Data</strong>
          </div>
          <p>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies. We will also retain Usage Data
            for internal analysis purposes. Usage Data is generally retained for
            a shorter period, except when this data is used to strengthen the
            security or to improve the functionality of our Service, or we are
            legally obligated to retain this data for longer time periods.
          </p>
          <div className="title02 title2">
            <strong>Transfer of Data</strong>
          </div>
          <p>
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
          <p>
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            <label className="demafont">dema</label> will take all the steps
            reasonably necessary to ensure that your data is treated securely
            and in accordance with this Privacy Policy and no transfer of your
            Personal Data will take place to an organization or a country unless
            there are adequate controls in place including the security of your
            data and other personal information.
          </p>
          <div className="title02 title2">
            <strong>Disclosure of Data</strong>
          </div>
          <p>
            We may disclose personal information that we collect, or you
            provide:
          </p>
          <ol type="a">
            <li>
              <strong>Business Transaction.</strong> If we or our subsidiaries
              are involved in a merger, acquisition or asset sale, your Personal
              Data may be transferred.
            </li>
            <li>
              <strong>
                Other cases. We may disclose your information also:
              </strong>
            </li>
          </ol>
          <ol type="i">
            <li>to our subsidiaries and affiliates;</li>
            <li>to fulfill the purpose for which you provide it;</li>
            <li>
              for any other purpose disclosed by us when you provide the
              information;
            </li>
            <li>with your consent in any other cases;</li>
            <li>
              if we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of the Company, our customers, or
              others.
            </li>
          </ol>

          <div className="title02 title2">
            <strong>Security of Data</strong>
          </div>
          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
          <div className="title02 title2">
            <strong>
              Your Data Protection Rights Under General Data Protection
              Regulation (GDPR)
            </strong>
          </div>
          <p>
            If you are a resident of the European Union (EU) and European
            Economic Area (EEA), you have certain data protection rights,
            covered by GDPR. – See more at&nbsp;
            <a
              href="https://eur-lex.europa.eu/eli/reg/2016/679/oj"
              target="_blank"
            >
              https://eur-lex.europa.eu/eli/reg/2016/679/oj
            </a>
          </p>
          <p>
            We aim to take reasonable steps to allow you to correct, amend,
            delete, or limit the use of your Personal Data.
          </p>
          <p>
            If you wish to be informed what Personal Data we hold about you and
            if you want it to be removed from our systems, please email us
            at&nbsp;
            <a href="mailto:hello@dema.shop">hello@dema.shop</a>.
          </p>
          <p>
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <ol type="a">
            <li>
              the right to access, update or to delete the information we have
              on you;
            </li>
            <li>
              the right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete;
            </li>
            <li>
              the right to object. You have the right to object to our
              processing of your Personal Data;
            </li>
            <li>
              the right of restriction. You have the right to request that we
              restrict the processing of your personal information;
            </li>
            <li>
              the right to data portability. You have the right to be provided
              with a copy of your Personal Data in a structured,
              machine-readable and commonly used format;
            </li>
            <li>
              the right to withdraw consent. You also have the right to withdraw
              your consent at any time where we rely on your consent to process
              your personal information;
            </li>
          </ol>
          <p>
            Please note that we may ask you to verify your identity before
            responding to such requests. Please note, we may not able to provide
            Service without some necessary data.
          </p>
          <p>
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </p>

          <div className="title02 title2">
            <strong>
              Your Data Protection Rights under the California Privacy
              Protection Act (CalOPPA)
            </strong>
          </div>
          <p>
            CalOPPA is the first state law in the nation to require commercial
            websites and online services to post a privacy policy. The law’s
            reach stretches well beyond California to require a person or
            company in the United States (and conceivable the world) that
            operates websites collecting personally identifiable information
            from California consumers to post a conspicuous privacy policy on
            its website stating exactly the information being collected and
            those individuals with whom it is being shared, and to comply with
            this policy. – See more at:&nbsp;<br></br>
            <a className='biglinks'
              href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"
              target="_blank"
            >
              https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
            </a>
          </p>
          <p>According to CalOPPA we agree to the following:</p>
          <ol type="a">
            <li>users can visit our site anonymously;</li>
            <li>
              our Privacy Policy link includes the word “Privacy”, and can
              easily be found on the page specified above on the home page of
              our website;
            </li>
            <li>
              users will be notified of any privacy policy changes on our
              Privacy Policy Page;
            </li>
            <li>
              users are able to change their personal information by emailing us
              at <a href="mailto:hello@dema.shop">hello@dema.shop</a>.
            </li>
          </ol>

          <p>
            <strong>Our Policy on “Do Not Track” Signals</strong>
            <br></br>We honor Do Not Track signals and do not track, plant
            cookies, or use advertising when a Do Not Track browser mechanism is
            in place. Do Not Track is a preference you can set in your web
            browser to inform websites that you do not want to be tracked.
          </p>
          <p>
            You can enable or disable Do Not Track by visiting the Preferences
            or Settings page of your web browser.
          </p>

          <div className="title02 title2">
            <strong>Service Providers</strong>
          </div>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service <strong>(“Service Providers”)</strong>, provide Service
            on our behalf, perform Service-related services or assist us in
            analyzing how our Service is used.
          </p>
          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
          <div className="title02 title2">
            <strong>Analytics</strong>
          </div>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
          <p>
            <strong>Google Analytics</strong>
            <br></br>Google Analytics is a web analytics service offered by
            Google that tracks and reports website traffic. Google uses the data
            collected to track and monitor the use of our Service. This data is
            shared with other Google services. Google may use the collected data
            to contextualize and personalize the ads of its own advertising
            network.
          </p>
          <p>
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:&nbsp;
            <a target="_blank" href="https://policies.google.com/privacy?hl=en">
              https://policies.google.com/privacy?hl=en
            </a>
          </p>
          <p>
            We also encourage you to review the Google's policy for safeguarding
            your data:&nbsp;
            <a className='biglinks'
              target="_blank"
              href="https://support.google.com/analytics/answer/6004245."
            >
              https://support.google.com/analytics/answer/6004245.
            </a>
            .
          </p>
          <div className="title02 title2">
            <strong>Payments</strong>
          </div>
          <p>
            We may provide paid products and/or services within Service. In that
            case, we use third-party services for payment processing (e.g.
            payment processors).
          </p>
          <p>
            We will not store or collect your payment card details. That
            information is provided directly to our third-party payment
            processors whose use of your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>
          <p>The payment processors we work with are</p>
          <p>
            <strong>Electronic Merchant Systems:</strong> Their Privacy Policy
            can be viewed at:&nbsp;
            <a className='biglinks'
              target="_blank"
              href="https://www.emscorporate.com/privacy-policy#:~:text=In%20general%2C%20and%20except%20for,to%20run%20their%20everyday%20business"
            >
              https://www.emscorporate.com/privacy-policy#:~:text=In%20general%2C%20and%20except%20for,to%20run%20their%20everyday%20business
            </a>
          </p>
          <div className="title02 title2">
            <strong>Links to Other Sites</strong>
          </div>
          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third-party link, you will be directed to that
            third party's site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third-party sites or
            services.
          </p>
          <div className="title02 title2">
            <strong>Children's Privacy</strong>
          </div>
          <p>
            Our Service does not address anyone under the age of 18&nbsp;
            <strong>(“Children”).</strong>
          </p>
          <p>
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Child has provided us with Personal Data, please
            contact us. If we become aware that we have collected Personal Data
            from children without verification of parental consent, we take
            steps to remove that information from our servers.
          </p>
          <div className="title02 title2">
            <strong>Changes to This Privacy Policy</strong>
          </div>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            “effective date” at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <div className="title02 title2">
            <strong>Contact Us</strong>
          </div>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us by email at&nbsp;
            <a href="mailto:hello@dema.shop">hello@dema.shop</a>.
          </p>
        </div>
      </section>
    </PageLayout>
  );
}
