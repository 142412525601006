import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BlogsContext } from "../../Contexts/BlogsContext";

export default function Faqdata() {
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedQuestionsAnswers, setSelectedQuestionsAnswers] = useState([]);
  const { sectionsData, questionsData } = useContext(BlogsContext);

  useEffect(() => {
    if (sectionsData[0] && sectionsData[0].subsection) {
      setSelectedSection(
        `${sectionsData[0].section}/${sectionsData[0].subsection[0]}`
      );
    } else if (sectionsData[0]) {
      setSelectedSection(`${sectionsData[0].section}`);
    }
    var hashValue = window.location.hash.substr(1)
    if(hashValue){
      let decodedData = decodeURI(hashValue)
      let selectionFromUrl = decodedData.replace(/_/g, ' ');
      setSelectedSection(selectionFromUrl)
    }
    setSelectedQuestionsAnswers(questionsData[selectedSection]);
  }, [sectionsData, questionsData]);

  useEffect(() => {
    setSelectedQuestionsAnswers(questionsData[selectedSection]);
  }, [selectedSection]);

  const onClickParent = (section, subsection) => {
    if (subsection && subsection.length > 0) {
      setSelectedSection(`${section}/${subsection[0]}`);
    } else {
      setSelectedSection(`${section}`);
    }
  };

  const LeftPanel = () => {
    return (
      <ul>
        {sectionsData.map((faqParentHeaderKey) => {
          const { section, subsection } = faqParentHeaderKey;
          return (
            <li key={section} className={selectedSection.includes(section) ? "active" : ""}>
              <Link
                to="#"
                onClick={() => {
                  onClickParent(section, subsection);
                }}
              >
                <strong>
                  {/* Selling on <label className="demafont">dema</label> */}
                  {section}
                </strong>
              </Link>
              {subsection && (
                <ul>
                  {subsection.map((subsectionKey) => {
                    return (
                      <li
                        key={subsectionKey}
                        className={
                          selectedSection.includes(subsectionKey)
                            ? "active"
                            : ""
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            setSelectedSection(`${section}/${subsectionKey}`);
                          }}
                        >
                          {subsectionKey}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const qaDisplay = (question, answer) => {
    return (
      <div key={question} className="faq-qustionans">
        <p>
          <strong>{question}</strong>
        </p>
        <p>{answer}</p>
      </div>
    );
  };

  return (
    <section className="faqdetails-main pt">
      <div className="container">
        <div className="faqdetailsbox-main d-flex">
          <div className="faqpartleft">{LeftPanel()}</div>
          <div className="faqdetails-right">
            {selectedSection && (
              <div id="shoppingbasics" className="faq-detailsscroll">
                {selectedQuestionsAnswers?.map((qa) => {
                  return qaDisplay(qa.question, qa.answer);
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
