import React from 'react'
import { Link } from 'react-router-dom';
import PageLayout from "../../Components/PageLayout";

export default function termsofservice() {
  return (
    <PageLayout>
      <section className="faqbanner pb policiesmain">
        <div className="container">
          <div className="page-maintitle">
            <h1 className="title1">
              <span>Terms of Service and</span> Conditions of Use
            </h1>
            <span className="pagetitle">Last Updated: Aug 8, 2022</span>
          </div>
          <div className="faqround circle1"></div>
          <div className="faqround circle2"></div>
          <div className="faqround circle3"></div>
          <div className="faqround circle4"></div>
          <div className="faqround circle5"></div>
        </div>
      </section>

      <section className="privacypolicy pt">
        <div className="container">
          <p>
            <label className="demafont">dema</label>&nbsp;Platforms Corp. and/or
            its Products and Interfaces (hereafter, "
            <label className="demafont">dema</label>") provide features and
            services to you when you visit or shop at, with or through&nbsp;
            <label className="demafont">dema</label>’s website at&nbsp;
            <Link to="/">https://dema.shop/</Link>,&nbsp;
            <label className="demafont">dema</label>’s desktop, mobile or
            browser extension applications or any other software provided
            by&nbsp;<label className="demafont">dema</label>.
          </p>

          <p>
            By using&nbsp;<label className="demafont">dema</label>, you agree,
            on behalf of yourself, all members of your business, household and
            others who you are representing and affiliated for the purposes you
            used&nbsp;<label className="demafont">dema</label> at the time you
            were using under your account, to the following conditions.
          </p>

          <h2 className="title2 title02">
            <strong>Privacy</strong>
          </h2>
          <p>
            Please review our Privacy Notice, which also governs your use
            of&nbsp;<label className="demafont">dema</label>, to understand our
            practices.
          </p>

          <h2 className="title2 title02">
            <strong>Electronic Communications</strong>
          </h2>
          <p>
            When you use&nbsp;<label className="demafont">dema</label>, send
            e-mails, text messages or use other communication channels from any
            of the devices that you own to interface with&nbsp;
            <label className="demafont">dema</label>, its employees, staff and
            affiliates, you consent to receive communications from us
            electronically, such as e-mails, texts, mobile push notices, or
            notices and messages on this site or through the other&nbsp;
            <label className="demafont">dema</label> Services, such as our
            Message Center. You can retain copies of these communications for
            your records. You agree that all agreements, notices, disclosures,
            and other communications that we provide to you electronically
            satisfy any legal requirement that such communications be in
            writing.
          </p>

          <h2 className="title2 title02">
            <strong>Copyright</strong>
          </h2>
          <p>
            All content included in or made available through&nbsp;
            <label className="demafont">dema</label>, such as text, graphics,
            logos, button icons, images, audio clips, digital downloads, data
            compilations, and software is the property of&nbsp;
            <label className="demafont">dema</label>
            &nbsp;or its content suppliers and protected by United States and
            international copyright laws. The compilation of all content
            included in or made available through any&nbsp;
            <label className="demafont">dema</label> Service is the exclusive
            property of&nbsp;<label className="demafont">dema</label> and
            protected by U.S. and international copyright laws. All trademarks
            not owned by&nbsp;<label className="demafont">dema</label> that
            appear in any&nbsp;<label className="demafont">dema</label> Service
            are the property of their respective owners, who may or may not be
            affiliated with, connected to, or sponsored by&nbsp;
            <label className="demafont">dema</label>.
          </p>
          <h2 className="title2 title02">
            <strong>License and Access</strong>
          </h2>
          <p>
            All rights not expressly granted to you in these Conditions of Use
            or any Service Terms are reserved and retained by&nbsp;
            <label className="demafont">dema</label> or its licensors,
            suppliers, sellers, publishers, rightsholders, or other content
            providers. No&nbsp;<label className="demafont">dema</label> Service,
            nor any part of any&nbsp;<label className="demafont">dema</label>
            &nbsp;Service, may be reproduced, duplicated, copied, sold, resold,
            visited, or otherwise exploited for any commercial purpose without
            the express written consent of&nbsp;
            <label className="demafont">dema</label>. You may not frame or
            utilize framing techniques to enclose any trademark, logo, or other
            proprietary information (including images, text, page layout, or
            form) of&nbsp;<label className="demafont">dema</label> without
            express written consent. You may use&nbsp;
            <label className="demafont">dema</label>
            &nbsp;product & Services only as permitted by law. Any licenses
            granted explicitly or implicitly by&nbsp;
            <label className="demafont">dema</label> will terminate if you do
            not comply with these terms of Service and Conditions of Use
          </p>
          <h2 className="title2 title02">
            <strong>Your Account</strong>
          </h2>
          <p>
            You may need your own&nbsp;<label className="demafont">dema</label>
            &nbsp;account to use certain&nbsp;
            <label className="demafont">dema</label>&nbsp;Services, and you may
            be required to be logged in to the account and have a valid payment
            method associated with it. You are responsible for maintaining the
            confidentiality of your account and password and for restricting
            access to your account, and you agree to accept responsibility for
            all activities that occur under your account or password.&nbsp;
            <label className="demafont">dema</label> does sell products for
            children, but it sells them to adults, who can purchase with a
            credit card, debit card, bank transfer, wallet transfer or other
            permitted payment methods. If you are under 18, you may use
            the&nbsp;<label className="demafont">dema</label> Services only with
            involvement of a parent or guardian.&nbsp;
            <label className="demafont">dema</label> reserves the right to
            refuse service, terminate accounts, terminate your rights to
            use&nbsp;<label className="demafont">dema</label> Services, remove
            or edit content, or cancel orders in its sole discretion.
          </p>

          <h2 className="title2 title02">
            <strong>
              Reviews, Comments, Communications, and Other Content
            </strong>
          </h2>

          <p>
            When and as enabled you may post reviews, comments, photos, videos,
            and other content; and other communications; and submit suggestions,
            ideas, comments, questions, or other information, so long as the
            content is not illegal, obscene, threatening, defamatory, invasive
            of privacy, infringing of intellectual property rights (including
            publicity rights), or otherwise injurious to third parties or
            objectionable, and does not consist of or contain software viruses,
            political campaigning, commercial solicitation, chain letters, mass
            mailings, or any form of "spam" or unsolicited commercial electronic
            messages. You may not use a false identity including e-mail
            addresses or impersonate any person or entity, or otherwise mislead
            as to the source and origin of content.&nbsp;
            <label className="demafont">dema</label> reserves the right (but not
            the obligation) to remove or edit such content.&nbsp;
            <label className="demafont">dema</label> does not regularly review
            all posted content.
          </p>
          <p>
            If you do post content or submit material, and unless&nbsp;
            <label className="demafont">dema</label> indicates otherwise, you
            grant&nbsp;<label className="demafont">dema</label> a non-exclusive,
            royalty-free, perpetual, irrevocable, and fully sublicensable right
            to use, reproduce, modify, adapt, publish, perform, translate,
            create derivative works from, distribute, and display such content
            throughout the world in any media. You grant&nbsp;
            <label className="demafont">dema</label> and sublicensees the right
            to use the name that you submit in connection with such content, if
            they choose. You represent and warrant that you own or otherwise
            control all of the rights to the content that you post; that the
            content is accurate; that use of the content you supply does not
            violate this policy and will not cause injury to any person or
            entity; and that you will indemnify&nbsp;
            <label className="demafont">dema</label> for all claims resulting
            from content you supply.&nbsp;
            <label className="demafont">dema</label> has the right but not the
            obligation to monitor and edit or remove any activity or
            content.&nbsp;<label className="demafont">dema</label> takes no
            responsibility and assumes no liability for any content posted by
            you or any third party.
          </p>

          <h2 className="title2 title02">
            <strong>Intellectual Property Complaints</strong>
          </h2>
          <p>
            <label className="demafont">dema</label> respects the intellectual
            property of others. If you believe that your intellectual property
            rights are being infringed, please send an email to{" "}
            <strong>
              <a href="mailto:hello@dema.shop." target="_blank">
                hello@dema.shop.
              </a>
            </strong>
          </p>

          <h2 className="title2 title02">
            <strong>Risk of Loss</strong>
          </h2>
          <p>
            When <label className="demafont">dema</label>&nbsp;ships purchased
            and digital items from&nbsp;<label className="demafont">dema</label>
            &nbsp;owned and/or managed resources and facilities, such shipments
            are made pursuant to a shipment contract. This means that the risk
            of loss and title for such items pass to you upon delivery to the
            carrier.
          </p>

          <h2 className="title2 title02">
            <strong>Returns, Refunds and Title</strong>
          </h2>
          <p>
            <label className="demafont">dema</label>&nbsp;does not take title to
            returned items until the item arrives at&nbsp;
            <label className="demafont">dema</label>’s represented fulfillment
            center. At the discretion as per the agreement set by&nbsp;
            <label className="demafont">dema</label> and Seller, a refund may be
            issued without requiring a return. In this situation,&nbsp;
            <label className="demafont">dema</label> does not take title to the
            refunded item.
          </p>

          <h2 className="title2 title02">
            <strong>Product Descriptions</strong>
          </h2>
          <p>
            <label className="demafont">dema</label> attempts to be as accurate
            as possible. However,&nbsp;
            <label className="demafont">dema</label> does not warrant that
            product descriptions or other content of any&nbsp;
            <label className="demafont">dema</label>
            &nbsp;Service is accurate, complete, reliable, current, or error-free. If
            a product offered by&nbsp;
            <label className="demafont">dema</label> itself is not as described,
            your sole remedy is to return it in unused condition. Once services
            offered by&nbsp;
            <label className="demafont">dema</label> are rendered, they are not
            returnable and any relief may be sought by opening a case with&nbsp;
            <label className="demafont">dema</label> by e-mail at&nbsp;
            <a href="mailto:hello@dema.shop">hello@dema.shop</a>. In the case a
            resolution cannot be mutually agreed upon, the Seller has the right
            to a dispute resolution process.
          </p>

          <h2 className="title2 title02">
            <strong>Pricing</strong>
          </h2>
          <p>
            "List Price" means the suggested retail price of a product as
            provided by a manufacturer, supplier, or seller. With respect to
            items sold by&nbsp;<label className="demafont">dema</label>, we
            cannot confirm the price of an item until an order is placed.
            Despite&nbsp;<label className="demafont">dema</label>’s best
            efforts, a small number of the items in our catalog may be
            mispriced. If the correct price of an item sold through&nbsp;
            <label className="demafont">dema</label> is higher than our stated
            price, we will, at our discretion, either contact you for
            instructions before shipping or cancel your order and notify you of
            such cancellation. However, sellers may have and follow different
            policies in the event of a mispriced item. Please refer to the
            policies as set forth by the seller on the page outlining their
            Terms of Service and Conditions of Use.
          </p>

          <h2 className="title2 title02">
            <strong>App Permissions</strong>
          </h2>
          <p>
            When you use apps created by&nbsp;
            <label className="demafont">dema</label>, such as the browser
            extension or Mobile Apps, you may grant certain permissions to&nbsp;
            <label className="demafont">dema</label> on your device. Most mobile
            devices provide you with information about these permissions before
            they are granted.
          </p>

          <h2 className="title2 title02">
            <strong>Sanctions and Export Policy</strong>
          </h2>
          <p>
            You may not use anyn <label className="demafont">dema</label>
            &nbsp;Service if you are the subject of U.S. sanctions or of sanctions
            consistent with U.S. law imposed by the governments of the country
            where you are usingn <label className="demafont">dema</label>
            &nbsp;Services. You must comply with all U.S. or other export and
            re-export restrictions that may apply to goods, software
            (includingn&nbsp;
            <label className="demafont">dema</label> Software), technology, and
            services.
          </p>
          <h2 className="title2 title02">
            <strong>Other Businesses</strong>
          </h2>
          <p>
            Parties other than <label className="demafont">dema</label> operate
            stores, provide products, services or software, or sell product
            lines through <label className="demafont">dema</label>. In addition,
            we provide links to the sites of affiliated companies and certain
            other businesses. If you purchase any of the products or services
            offered by these businesses or individuals, you are purchasing
            directly from those third parties, not from&nbsp;
            <label className="demafont">dema</label>. We are not responsible for
            examining or evaluating, and we do not warrant, the offerings of any
            of these businesses or individuals (including the content of their
            Web sites). <label className="demafont">dema</label> does not assume
            any responsibility or liability for the actions, product, and
            content of all these and any other third parties. You should
            carefully review their privacy statements and other conditions of
            use.
          </p>
          <h2 className="title2 title02">
            <strong>
              Disclaimer Of Warranties and Limitation Of Liability
            </strong>
          </h2>
          <p>
            THE <label className="demafont">dema</label> SERVICES AND ALL
            INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND
            OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
            THROUGH <label className="demafont">dema</label> WEBSITE, BROWSER
            EXTENSIONS, MOBILE APPS ARE PROVIDED BY&nbsp;
            <label className="demafont">dema</label> ON AN "AS IS" AND "AS
            AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING.&nbsp;
            <label className="demafont">dema</label> MAKES NO REPRESENTATIONS OR
            WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF
            THE <label className="demafont">dema</label> SERVICES, OR THE
            INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR
            OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
            THROUGH THE <label className="demafont">dema</label> SERVICES,
            UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR
            USE OF THE <label className="demafont">dema</label> IS AT YOUR SOLE
            RISK.
          </p>
          <p>
            TO THE FULL EXTENT PERMISSIBLE BY LAW,&nbsp;
            <label className="demafont">dema</label> DISCLAIMS ALL WARRANTIES,
            EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
            WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            PURPOSE.&nbsp;
            <label className="demafont">dema</label> DOES NOT WARRANT THAT
            THE&nbsp;
            <label className="demafont">dema</label> SERVICES, INFORMATION,
            CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES
            INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE&nbsp;
            <label className="demafont">dema</label>
            &nbsp;SERVICES OR ELECTRONIC COMMUNICATIONS SENT FROM&nbsp;
            <label className="demafont">dema</label> ARE FREE OF VIRUSES OR
            OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY
            LAW,&nbsp;
            <label className="demafont">dema</label> WILL NOT BE LIABLE FOR ANY
            DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY&nbsp;
            <label className="demafont">dema</label> SERVICE, OR FROM ANY
            INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR
            OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
            THROUGH ANY <label className="demafont">dema</label> SERVICE,
            INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL,
            PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN
            WRITING.
          </p>
          <h2 className="title2 title02">
            <strong>Disputes</strong>
          </h2>
          <p>
            Any dispute or claim relating in any way to your use of any products
            and services provided by <label className="demafont">dema</label>
            &nbsp;will be adjudicated in the state or Federal courts in Santa
            Clara County, California, and you consent to exclusive jurisdiction
            and venue in these courts. We each waive any right to a jury trial.
          </p>
          <h2 className="title2 title02">
            <strong>Applicable Law</strong>
          </h2>
          <p>
            By using any <label className="demafont">dema</label> service, you
            agree that applicable federal law, and the laws of the state of
            California, without regard to principles of conflict of laws, will
            govern these Conditions of Use and any dispute of any sort that
            might arise between you and <label className="demafont">dema</label>
            .
          </p>
          <h2 className="title2 title02">
            <strong>Site Policies, Modification, and Severability</strong>
          </h2>
          <p>
            Please review our other policies, posted on this site. These
            policies also govern your use of&nbsp;
            <label className="demafont">dema</label> Services. We reserve the
            right to make changes to our site, policies, Terms of Service and
            Conditions of Use at any time. If any of these conditions shall be
            deemed invalid, void, or for any reason unenforceable, that
            condition shall be deemed severable and shall not affect the
            validity and enforceability of any remaining condition.
          </p>

          <div className="text-center">
            <h2 className="title2 title02">
              <strong>Contacting us</strong>
            </h2>
            <p>
              <label className="demafont">dema</label>
              &nbsp;Platforms Corp<br></br>
              7052 Santa Teresa Blvd #1052 <br></br>
              San Jose, CA 95139<br></br>
              (408) 641-1827<br></br>
              <a href="https://dema.shop/" target="_blank">
                https://dema.shop/
              </a>
              <br></br>
              <a href="mailto:hello@dema.shop">hello@dema.shop</a>
              <br></br>
            </p>
          </div>
          <h2 className="title2 title02">
            <strong>
              Additional <label className="demafont">dema</label> Software Terms
            </strong>
          </h2>
          <p>
            The following terms (“Software Terms”) apply to any software
            (including any updates or upgrades to the software) and any related
            documentation we make available to you in connection with&nbsp;
            <label className="demafont">dema</label> Services (the "
            <label className="demafont">dema</label> Software").
          </p>

          <ol type="1">
            <li>
              <strong>
                Use of the <label className="demafont">dema</label> Software.
              </strong>
              &nbsp;You may use <label className="demafont">dema</label>
              &nbsp;Software solely for purposes of enabling you to use the&nbsp;
              <label className="demafont">dema</label> Services as provided
              by&nbsp;
              <label className="demafont">dema</label>, and as permitted by
              these Conditions of Use and any Service Terms. You may not
              incorporate any portion of the&nbsp;
              <label className="demafont">dema</label> Software into other
              programs or compile any portion of it in combination with other
              programs, or otherwise copy (except to exercise rights granted in
              this section), modify, create derivative works of, distribute,
              assign any rights to, or license the&nbsp;
              <label className="demafont">dema</label> Software in whole or in
              part. All software used in any&nbsp;
              <label className="demafont">dema</label> Service is the property
              of <label className="demafont">dema</label> or its software
              suppliers and is protected by United States and international
              copyright laws.
            </li>
            <li>
              <strong>Use of Third-Party Services.</strong> When you use
              the&nbsp;
              <label className="demafont">dema</label> Software, you may also be
              using the services of one or more third parties, such as a
              wireless carrier or a mobile software provider. Your use of these
              third-party services may be subject to the separate policies,
              terms of use, and fees of these third parties.
            </li>
            <li>
              <strong>No Reverse Engineering.</strong> You may not reverse
              engineer, decompile or disassemble, tamper with, or bypass any
              security associated with the&nbsp;
              <label className="demafont">dema</label> Software, whether in
              whole or in part.
            </li>
            <li>
              <strong>Updates.</strong> We may offer automatic or manual updates
              to the <label className="demafont">dema</label> Software at any
              time and without notice to you.
            </li>
            <li>
              <strong>Government End Users.</strong> If you are a U.S.
              Government end user, we are licensing the&nbsp;
              <label className="demafont">dema</label>
              &nbsp;Software to you as a "Commercial Item" as that term is
              defined in the U.S. Code of Federal Regulations (see 48 C.F.R. §
              2.101), and the rights we grant you to the&nbsp;
              <label className="demafont">dema</label> Software are the same as
              the rights we grant to all others under these Conditions of Use.
            </li>
            <li>
              <strong>Conflicts.</strong> In the event of any conflict between
              these Conditions of Use and any other&nbsp;
              <label className="demafont">dema</label> or third-party terms
              applicable to any portion of&nbsp;
              <label className="demafont">dema</label> Software, such as
              open-source license terms, such other terms will control as to
              that portion of the <label className="demafont">dema</label>
              &nbsp;Software and to the extent of the conflict.
            </li>
          </ol>
          <h2 className="title2 title02">
            <strong>How to Serve a Subpoena or Other Legal Process</strong>
          </h2>
          <p>
            <label className="demafont">dema</label>
            &nbsp;Platforms Corp<br></br>
            7052 Santa Teresa Blvd #1052 <br></br>
            San Jose, CA 95139<br></br>
            (408) 641-1827<br></br>
            <a href="https://dema.shop/" target="_blank">
              https://dema.shop/
            </a>
            <br></br>
            <a href="mailto:hello@dema.shop">hello@dema.shop</a>
            <br></br>
          </p>
          <p>
            Please note that providing detailed and accurate information at the
            outset will facilitate efficient processing of your request. That
            information will include, for example, e-mail and/or credit card
            number used to make purchases for retail purchase information; the
            name, e-mail, and physical address of a seller for seller
            information; and IP address and complete time stamps.
          </p>

          <ul className='cardspay'>
           <li><img src='images/termsofservice/amex.svg' alt=''/></li>
           <li><img src='images/termsofservice/discover.svg' alt=''/></li>
           <li><img src='images/termsofservice/mastercard.svg' alt=''/></li>
           <li><img src='images/termsofservice/visa.svg' alt=''/></li>
          </ul>

        </div>
      </section>
    </PageLayout>
  );
}
