import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [navclick, setNavclick] = useState(false);
  const handleClick = () => {
    setNavclick(!navclick);
    if (navclick) {
      document.body.classList.remove("hidemobile");
    } else {
      document.body.classList.add("hidemobile");
    }
  };

  const [navopenclick, setNavopenclick] = useState(false);
  const onhandleClick = () => {
    setNavopenclick(!navopenclick);
    if (navopenclick) {
      document.body.classList.remove("hidedestop");
    } else {
      document.body.classList.add("hidedestop");
    }
  };

  const body = document.body;
  //const lottiePlayer = document.querySelector("lottie-player");
  const scrollDown = "scroll-down";
  let lastScroll = 0;

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 200) {
      body.classList.remove(scrollDown);
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
      // down
      body.classList.remove(scrollDown);
      body.classList.add(scrollDown);
      //lottiePlayer.play();
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains(scrollDown)
    ) {
      // up
      body.classList.remove(scrollDown);
      body.classList.add(scrollDown);
      // lottiePlayer.stop();
    }
    lastScroll = currentScroll;
  });

  return (
    // onClick={componentDidMount}
    <header>
      <div className="container-fluid">
        <div className="headertop d-flex">
          <div className="logo" onClick={() => window.scrollTo(0, 0)}>
            <Link to="/">
              <figure>
                <img alt="dema" title="dema" src="/images/logo.svg" />
              </figure>
            </Link>
          </div>
          <div className="navigation" onClick={handleClick}>
            <div className="navicon">
              <div className={navclick ? "closemenu" : "openmenu"}></div>
            </div>

            <ul
              className={navclick ? "d-flex active" : "d-flex"}
              onClick={() => window.scrollTo(0, 0)}
            >

              {/* <li>
                <Link to="/watchthepitch" className="active">
                  <span>
                    <img alt="" src="/images/menu-icons/play.svg" />
                  </span>
                  Watch The Pitch
                </Link>
              </li> */}
              <li>
                <Link to="/sellers" className="active">
                  <span>
                    <img alt="" src="/images/menu-icons/menuicon5.png" />
                  </span>
                  Sellers
                </Link>
              </li>
              <li>
                <Link to="/shoppers">
                  <span>
                    <img alt="" src="/images/menu-icons/menuicon6.png" />
                  </span>
                  Shoppers
                </Link>
              </li>
              <li className="mobailshowmenu">
                <Link to="/community">
                  <span>
                    <img alt="" src="/images/menu-icons/menuicon1.png" />
                  </span>
                  Community
                </Link>
              </li>
              <li className="mobailshowmenu rightsidemenu">
                <ul>
                  <li>
                    <a href="https://medium.com/@demadotshop/tired-of-taking-the-blue-pill-lets-introduce-you-to-an-easy-to-swallow-red-pill-for-commerce-2cc3378c5ce9">
                      <span>
                        <img alt="" src="/images/menu-icons/menuicon3.png" />
                      </span>
                      Blog
                    </a>
                  </li>
                  <li>
                    <Link to="/about">
                      <span>
                        <img alt="" src="/images/menu-icons/menuicon2.png" />
                      </span>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq">
                      <span>
                        <img alt="" src="/images/menu-icons/menuicon4.png" />
                      </span>
                      FAQ
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="signupmenu">
                <a href="/sellers#seller_signup" className="btn black">
                  Sellers Signup
                </a>
              </li>

              <li className="sellers">
                <a href="/shoppers#shopper_signup" className="btn">
                  Buy on <span>dema</span>
                </a>
              </li>
              <li className="mobilelogoshow logomobileshow">
                <Link to="/">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="dema"
                      title="dema"
                      src="/images/menu-icons/mobilelogo.svg"
                    />
                  </figure>
                </Link>
              </li>
            </ul>
          </div>

          <div className="destopna" onClick={onhandleClick}>
            <div className="menuicon">
              <div
                className={navopenclick ? "closemenudestop" : "openmenudestop"}
              ></div>
            </div>
            <div
              className={
                navopenclick ? "menudespohideshow active" : "menudespohideshow"
              }
            >
              <div className="navfuullbg">
                <ul onClick={() => window.scrollTo(0, 0)}>
                  <li>
                    <Link to="/community">
                      <span>
                        <img alt="" src="/images/menu-icons/menuicon1.png" />
                      </span>
                      Community
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <span>
                        <img alt="" src="/images/menu-icons/menuicon2.png" />
                      </span>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <a href="https://medium.com/@demadotshop/tired-of-taking-the-blue-pill-lets-introduce-you-to-an-easy-to-swallow-red-pill-for-commerce-2cc3378c5ce9">
                      <span>
                        <img alt="" src="/images/menu-icons/menuicon3.png" />
                      </span>
                      Blog
                    </a>
                  </li>
                  <li>
                    <Link to="/faq">
                      <span>
                        <img alt="" src="/images/menu-icons/menuicon4.png" />
                      </span>
                      FAQ
                    </Link>
                  </li>
                </ul>
                <div className="logo">
                  <Link to="/">
                    <figure>
                      <img
                        alt="dema"
                        title="dema"
                        src="/images/menu-icons/smalllogo.svg"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
