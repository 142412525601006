import React from 'react'
import PageLayout from '../../Components/PageLayout'

export default function refundandcancellationpolicy() {
  return (
    <PageLayout>
      <section className="faqbanner pb policiesmain">
        <div className="container">
          <div className="page-maintitle">
            <h1 className="title1">
              <span>Return</span> Policy
            </h1>
            <span className="pagetitle">Last Updated: Aug 8, 2022</span>
          </div>
          <div className="faqround circle1"></div>
          <div className="faqround circle2"></div>
          <div className="faqround circle3"></div>
          <div className="faqround circle4"></div>
          <div className="faqround circle5"></div>
        </div>
      </section>

      <section className="privacypolicy pt">
        <div className="container">
          <h2 className="title2">
            <strong>Returns Policies</strong>
          </h2>
          <p>
            Most sellers on <label className="demafont">dema</label> offer
            returns for items within 30 days of receipt of shipment.
            Instructions for returns, return label generation along with
            shipment / drop-off options for returning the items would be
            provided to you on your order/returns section by the sellers.&nbsp;
            <label className="demafont">dema</label> will not specify the return
            policies. They are developed and enforced by sellers in coordination
            with <label className="demafont">dema</label>
            &nbsp;facilities for a seamless experience to both buyers and
            sellers.&nbsp;
            <label className="demafont">dema</label> suggests what are the
            preferred and most offered return policies on the platform to the
            sellers.
          </p>
          <p>
            While the individual return policies are set by the sellers, the
            following items generally can’t be returned.
          </p>
          <ul className="aside">
            <li>
              Grocery Products, Jewelry and some restricted category items, Some
              health & personal care products
            </li>
            <li>Products missing the serial number or UPC</li>
            <li>
              ny software products, Online subscriptions after they have been
              accessed
            </li>
            <li>Gift cards, prepaid cards etc (except as required by law)</li>
            <li>Items with special shipping restrictions</li>
            <li>Any items more than 30 days after delivery</li>
          </ul>
          <p></p>

          <p>
            After the carrier receives your item, it can take up to four weeks
            for the seller to receive and process your return.&nbsp;
            <label className="demafont">dema</label> typically process returns
            within 3-5 business days after the carrier delivers the item and
            seller confirms this. When <label className="demafont">dema</label>
            &nbsp;completes processing your return, a refund is issued to your
            selected payment method. In limited cases, depending on the seller
            policies <label className="demafont">dema</label> may issue an
            instant refund as <label className="demafont">dema</label> credit to
            your account. This credit can only be used when shopping on&nbsp;
            <label className="demafont">dema</label> or as described in offers
            provided by&nbsp;<label className="demafont">dema</label>.
          </p>

          <h2 className="title2 title02">
            <strong>Cancellation Policies</strong>
          </h2>
          <p>
            You can cancel orders that haven't entered the shipping process yet.
            You can go to your order page and select the order you want to
            cancel and Cancel items in an order or cancel the complete order.
            After submitting the cancellation, you'll receive a confirmation
            message on the screen. You'll also receive a mail / message in your
            account. Typically, it will take 30 - 60 mins to stage the orders
            for fulfillment & shipping and you can cancel the order within this
            time frame and payment would be refunded to your account within 3-5
            business days. Once this 30-60 mins time post order has been placed,
            sometimes the option to request cancellation expires as the seller
            would have already shipped the order.
          </p>
          <p>
            If you no longer see Request Cancellation, contact the seller for
            instructions. In most cases, the seller might cancel it on their end
            or suggest you select cancel the delivery if it’s already shipped
            via one of the methods prescribed by the seller (e.g.: directly
            intimate to the shipping carrier to cancel and send it back to
            seller). If this option is not available (e.g.: because multiple
            carriers were involved in shipping this order), you may be able to
            return the item(s) purchased using the returns process pursuant to
            the seller’s terms and conditions for each item.
          </p>
        </div>
      </section>
    </PageLayout>
  );
}
