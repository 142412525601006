import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { BlogsContext } from "./Contexts/BlogsContext";
import { BLOGS_URL, FAQ_URL, TOP_BLOGS_URL } from "./Helpers/ApiHelper";
import Navigator from './Navigator';
import axios from 'axios';

function App() {
  const [blogs, setBlogs] = useState([])
  const [topBlogs, setTopBlogs] = useState([])
  const [sectionsData, setSectionsData] = useState([]);
  const [questionsData, setQuestionsData] = useState({});
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    axios.get(TOP_BLOGS_URL).then((response) => {
      setTopBlogs(response.data)
    });
    axios.get(BLOGS_URL).then((response) => {
      setBlogs(response.data)
    });
  }, []);

  useEffect(() => {
    axios.get(FAQ_URL).then((response) => {
      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setFaq(response.data.data)
        let sections = [];
        let questionsData = {}
        response.data.data.map(faq => {

          let responseObj = {}
          if(faq.attributes.subsection && faq.attributes.subsection.length > 0){
            responseObj = { section: faq.attributes.faq_section };
            let subsectionData = []
            faq.attributes.subsection.map(subsectionObj => {
              questionsData[`${faq.attributes.faq_section}/${subsectionObj.title}`] = subsectionObj.qa
              return subsectionData.push(subsectionObj.title)
            })
            responseObj = { ...responseObj, subsection: subsectionData };
          } else {
            responseObj = { section: faq.attributes.faq_section };
            questionsData[faq.attributes.faq_section] = faq.attributes.qa
          }
          sections.push(responseObj);
          setSectionsData(sections)
          setQuestionsData(questionsData)
          return sections
        })
      }
    }).catch(error=>{
      console.log(error)
    });
  }, []);


  return (
    <div className="App">
      <BlogsContext.Provider
        value={{ blogs, topBlogs, sectionsData, questionsData, faq }}
      >
        <BrowserRouter>
          <Navigator />
        </BrowserRouter>
      </BlogsContext.Provider>
    </div>
  );
}

export default App;
